export default function initSuccessModal() {
  let elements = Array.prototype.slice.apply(
    document.querySelectorAll(".success-modal__close-button")
  );
  elements.forEach(element => {
    element.addEventListener("click", () => {
      $.fancybox.close();
    });
  });
}
