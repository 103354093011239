import {copyParams, override} from "../core";

export default function filters(view) {
  view.filterForm = view.element.querySelector(".js-catalog-view__filter-form");
  if (!view.filterForm) {
    return view;
  }
  view.filterSubmitButtons = view.element.querySelectorAll(".js-catalog-view__filter-submit");
  view.filterSubmitButtons = Array.prototype.slice.apply(view.filterSubmitButtons);

  view.getFilterParams = () => {
    const params = new URLSearchParams();
    const inputs = view.filterForm.querySelectorAll("input");
    for (const input of inputs) {
      if (!input.name || !input.value || input.disabled) {
        continue
      }

      if (input.type === "checkbox" && !input.checked) {
        continue
      }

      params.append(input.name, input.value);
    }
    return params;
  }

  view.getSearchParams = override(view.getSearchParams, callSuper => {
    const params = callSuper();
    copyParams(view.getFilterParams(), params);
    return params;
  });

  let lastSearchParams = new URLSearchParams();
  const onChange = () => {
    const searchParams = view.getSearchParams();
    if (searchParams.toString() === lastSearchParams.toString()) {
      return;
    }
    lastSearchParams = searchParams;

    view.debounceReload();
  };
  view.filterForm.addEventListener("change", onChange);
  view.filterForm.addEventListener("input", onChange);

  view.filterForm.addEventListener("keydown", event => {
    // Enter
    if (event.target.tagName === "INPUT" && event.keyCode === 13) {
      if (view.isLoading) return;
      view.reload();
      event.target.blur();
    }
  });

  for (const button of view.filterSubmitButtons) {
    button.addEventListener("click", () => {
      if (view.isLoading) return;
      view.reload();
    });
  }

  view.setLoading = override(view.setLoading, (callSuper, loading) => {
    callSuper(loading);

    for (const button of view.filterSubmitButtons) {
      if (loading) {
        button.classList.add("preloader--active");
      } else {
        button.classList.remove("preloader--active");
      }
    }
  })

  return view;
}
