export default function initPaymentButton() {
  document.addEventListener("click", event => {
    let paymentButton = event.target.closest(".payment-button");
    if (!paymentButton) return;

    handleClick(paymentButton);
  });
}

function handleClick(paymentButton) {
  let radioButton = paymentButton.querySelector("input[type=radio]");
  if (!radioButton) return;

  let parentForm = paymentButton.closest("form");
  if (radioButton.name && parentForm) {
    let allRadioButtons = parentForm.querySelectorAll(
      'input[type=radio][name="' + radioButton.name + '"]'
    );
    for (let i = 0; i < allRadioButtons.length; i++) {
      const rb = allRadioButtons[i];
      if (rb !== radioButton && rb.checked) {
        rb.checked = false;
        radioButton.dispatchEvent(new Event("change", { bubbles: true }));

        let pb = rb.closest(".payment-button");
        if (pb) pb.classList.remove("payment-button--active");
      }
    }
  }

  if (!radioButton.checked) {
    radioButton.checked = true;
    radioButton.dispatchEvent(new Event("change", { bubbles: true }));
    paymentButton.classList.add("payment-button--active");
  }
}
