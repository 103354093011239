import {override} from "../core";

export default function extraSearchParams(view) {
  view.getSearchParams = override(view.getSearchParams, callSuper => {
    const params = callSuper();

    const prefix = "data-extra-"
    for (const attr of view.element.attributes) {
      if (attr.name.indexOf(prefix) === 0) {
        const fieldName = attr.name.slice(prefix.length);
        params.set(fieldName, attr.value);
      }
    }

    return params;
  });

  return view;
}
