const SEARCH_SUGGESTIONS_UPDATE = "SEARCH_SUGGESTIONS_UPDATE";
const SEARCH_SUGGESTIONS_SHOW_PRELOADER = "SEARCH_SUGGESTIONS_SHOW_PRELOADER";

export const initialSearchState = {
  search: {
    suggestions: {
      numbers: [],
      articles: [],
      news: [],
      flatpages: [],
      tariffs: [],
      tariffs_catalogs: [],
      numbers_catalogs: [],
      total: 0
    },
    query: "",
    showPreloader: true
  }
};

export function showPreloaderSuggestionsCreator() {
  return {
    type: SEARCH_SUGGESTIONS_SHOW_PRELOADER,
    showPreloader: true
  };
}

export function updateSearchSuggestionsCreator(suggestions, query) {
  return {
    type: SEARCH_SUGGESTIONS_UPDATE,
    suggestions: suggestions,
    query: query,
    showPreloader: false
  };
}

export function searchReducer(state = initialSearchState, action) {
  switch (action.type) {
    case SEARCH_SUGGESTIONS_UPDATE: {
      return Object.assign({}, state, {
        suggestions: action.suggestions,
        query: action.query,
        showPreloader: action.showPreloader
      });
    }
    case SEARCH_SUGGESTIONS_SHOW_PRELOADER: {
      return Object.assign({}, state, {
        showPreloader: action.showPreloader
      });
    }
    default:
      return state;
  }
}
