export function initModalScrollbars() {
  $(".fancybox").fancybox({
    afterShow: function() {
      $(".modal").perfectScrollbar("update");
    }
  });

  $(".modal").perfectScrollbar();
}

export function showSuccessFancybox(el = "#show-success") {
  $.fancybox.open($(el));
}

export function mapErrorsToForm(errors, $validator) {
  $validator.showErrors(errors);
}