function initQuickFill(button) {
  button.addEventListener("click", () => {
    const targets = parseTargets(button);
    for (const target of targets) {
      if (target.element.value !== target.value) {
        target.element.value = target.value;
        target.element.dispatchEvent(new Event("change", {bubbles: true}));
      }
    }
  });
}

function parseTargets(button) {
  const targetSelectors = button.getAttribute("data-target").split(";");
  const elements = targetSelectors.map(s => document.querySelector(s));
  const values = button.getAttribute("data-value").split(";");

  const targets = [];
  elements.forEach((element, index) => {
    if (!element) return;

    const value = values[index] || '';
    targets.push({element, value});
  });

  return targets;
}

export default function initQuickFillModule() {
  const buttons = document.querySelectorAll(".js-quick-fill");
  for (const button of buttons) {
    initQuickFill(button);
  }
}
