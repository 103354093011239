import debounce from "lodash/debounce";
import { getArticles } from "../../utils/api";
import { renderNoResultsMessage, renderPlainArticleItem } from "../templates";

export function initArticles() {
  let moreArticlesButton = $("#more_articles"),
    articleList = $("#article_list"),
    providerFilters = $("#article_provider_filters"),
    page = 2;

  moreArticlesButton.click(function(e) {
    let params = providerFilters.serialize();

    moreArticlesButton.text("Загрузка");

    getArticles(params, page)
      .then(data => {
        moreArticlesButton.text("Показать еще статьи");
        if (data.next) {
          page += 1;
        } else {
          moreArticlesButton.hide();
        }
        let html = data.results
          .map(function(article) {
            return renderPlainArticleItem(article);
          })
          .join("");
        articleList.append(html);
      })
      .fail(() => {
        moreArticlesButton.text("Показать еще статьи");
      });

    e.preventDefault();
  });

  const reloadList = debounce(function() {
    let params = providerFilters.serialize(),
      page = 1;

    getArticles(params, page).then(data => {
      if (data.next) {
        page += 1;
        moreArticlesButton.show();
      } else {
        moreArticlesButton.hide();
      }
      let html;
      if (data.results.length > 0) {
        html = data.results
          .map(function(article) {
            return renderPlainArticleItem(article);
          })
          .join("");
      } else {
        html = renderNoResultsMessage("Ничего не найдено");
      }
      articleList.html(html);
    });
  }, 1000);
  providerFilters.on("change", reloadList);
}
