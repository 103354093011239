import {override} from "../core";

export default function sortSelect(view) {
  view.sortSelect = view.element.querySelector(".js-catalog-view__sort-select");
  if (!view.sortSelect) {
    return view;
  }

  view.getOrdering = () => {
    return view.sortSelect.value;
  };

  view.getSearchParams = override(view.getSearchParams, callSuper => {
    const params = callSuper();
    params.set("ordering", view.getOrdering());
    return params;
  });

  view.sortSelect.addEventListener("change", () => {
    view.reload();
  });

  return view;
}
