import React from "react";
import PropTypes from "prop-types";
import { providerPropTypes } from "./prop_types";

export default function ProviderIcon(props) {
  let classes = ["icon-provider"];
  if (props.small) {
    classes.push("icon-small");
  }
  if (props.className) {
    classes.push(props.className);
  }

  let backgroundImage = undefined;
  if (props.provider.icon) {
    backgroundImage = `url('${props.provider.icon}')`;
  }

  return (
    <div
      className={classes.join(" ")}
      style={{ backgroundImage }}
      title={props.provider.display_name}
    />
  );
}

ProviderIcon.propTypes = {
  provider: providerPropTypes.isRequired,
  small: PropTypes.bool,
  className: PropTypes.string
};
