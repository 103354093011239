function scrollToEnd(element, dir) {
  if (dir === "h") {
    element.scrollTo({ left: element.scrollWidth, behavior: "instant" });
  }
}

export default function initScrollToEnd() {
  const elements = document.querySelectorAll("[data-init-scroll-to-end]");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const dir = element.getAttribute("data-init-scroll-to-end");
    scrollToEnd(element, dir);
  }
}
