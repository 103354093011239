import React from "react";
import PropTypes from "prop-types";

import { chunk } from "../../../utils/utils";
import FullSubdomainsList from "./FullSubdomainsList";

class Subdomains extends React.Component {
  render() {
    let {
        shortListSubdomains,
        filterSubdomainsByLetter,
        filterOut,
        isOpenFullList,
        isOpenShortList
      } = this.props,
      columns = chunk(shortListSubdomains, 5);

    return (
      <div className="region-selector">
        <div className="region-selector__title content-heading">
          Выберите свой регион
        </div>
        <div className="region-selector__search-field search-field-group">
          <input
            onChange={e => filterOut(e.target.value)}
            type="text"
            name="city"
            placeholder="Поиск города"
            className="search-field-group__input"
          />
          <button type="button" className="search-field-group__button" />
        </div>

        <div
          className="region-selector__short-list"
          style={{ display: isOpenShortList ? undefined : "none" }}
        >
          {columns.map(this.createColumn.bind(this))}
        </div>

        <div style={{ display: isOpenFullList ? undefined : "none" }}>
          <FullSubdomainsList
            subdomains={filterSubdomainsByLetter}
          />
        </div>
      </div>
    );
  }

  createColumn(column, index, array) {
    const isLastColumn = index == array.length - 1;

    return (
      <div key={column[0].id} className="region-selector__short-list-column">
        {column.map(this.createListItem)}
        {isLastColumn && (
          <button
            type="button"
            className="region-selector__list-button"
            onClick={this.props.toggleFullList}
          >
            Полный список
          </button>
        )}
      </div>
    );
  }

  createListItem(subdomain) {
    return (
      <a
        key={subdomain.id}
        href={subdomain.change_url}
        className="region-selector__short-list-link"
      >
        {subdomain.display_name}
      </a>
    );
  }
}

Subdomains.propTypes = {
  isOpenShortList: PropTypes.bool,
  isOpenFullList: PropTypes.bool,
  shortListSubdomains: PropTypes.array,
  filterSubdomainsByLetter: PropTypes.array,
  filterOut: PropTypes.func,
  toggleFullList: PropTypes.func
};

export default Subdomains;
