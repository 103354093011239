function initInternetReasonsButton(button) {
  const input = button.querySelector("input");

  button.addEventListener("click", () => {
    input.disabled = !input.disabled;
    input.dispatchEvent(new Event("change", {bubbles: true}));
  })

  input.addEventListener("change", () => {
    if (input.disabled) {
      button.classList.remove("internet-reasons__button--active");
    } else {
      button.classList.add("internet-reasons__button--active");
    }
  });
}

export default function initInternetReasonsModule() {
  const buttons = document.querySelectorAll(".internet-reasons__button");
  for (const button of buttons) {
    initInternetReasonsButton(button);
  }
}
