import React from "react";
import ReactDOM from "react-dom";
import { initOrderCheckoutForm } from "../../react/modules/order";
import { store } from "../../react/store";
import {
  lazyLoadOrderCheckout,
  lazyLoadOrderResult
} from "../lazy-load";

export function renderOrderCheckout() {
  const targets = Array.prototype.slice.apply(document.querySelectorAll("[data-react=order-checkout]"));
  if (targets.length > 0) {
    lazyLoadOrderCheckout().then(({ default: OrderCheckout }) => {
      targets.forEach(target => {
        ReactDOM.render(
          React.createElement(OrderCheckout, { store: store }),
          target
        );
      });
    });
  }
}

export function renderOrderResult() {
  const targets = Array.prototype.slice.apply(document.querySelectorAll("[data-react=order-result-items]"));
  if (targets.length > 0) {
    lazyLoadOrderResult().then(({ default: OrderResult }) => {
      targets.forEach(target => {
        ReactDOM.render(
          React.createElement(OrderResult, { store: store }),
          target
        );
      });
    });
  }
}

export function initOrder() {
  renderOrderCheckout();
  renderOrderResult();
  initOrderCheckoutForm();
}
