export const initialCartState = {
    cart: {
        hasLoaded: false,
        items: [],
        totalPrice: "0",
        itemsCount: window.CART_ITEMS_COUNT,
        cartNumbers: window.CART_NUMBERS,
        cartTariffs: window.CART_TARIFFS,
        cartDevices: window.CART_DEVICES,
        relatedDevices: window.RELATED_DEVICES,
        isRelatedDevicesInCart: window.IS_RELATED_DEVICES_IN_CART,
        suitableTariffs: {},
        suitableNumbers: {},
        showRefill: false,
        showRefillItems: false,
        refillItems: [],
        advancePayment: null,
        freeDelivery: false,
        promocode: null,
        promocodeDiscount: null
    }
};


const INIT_EMPTY_CART = 'INIT_EMPTY_CART';
const INIT_CART = 'INIT_CART';
const UPDATE_CART = 'UPDATE_CART';
const CLEAR_CART = 'CLEAR_CART';
const CART_ITEM_LOAD_SUITABLE_TARIFFS = 'CART_ITEM_LOAD_SUITABLE_TARIFFS';
const CART_ITEM_LOAD_SUITABLE_NUMBERS = 'CART_ITEM_LOAD_SUITABLE_NUMBERS';

const IS_CART_PAGE = location.pathname == CART_URL;


export function initEmptyCartCreator() {
    return {
        type: INIT_EMPTY_CART
    }
}

export function initCartCreator(data) {
    return {
        type: INIT_CART,
        items: data.items,
        totalPrice: data.total_price,
        showRefill: data.show_refill,
        advancePayment: data.advance_payment,
        freeDelivery: data.has_free_delivery,
        promocode: data.promocode,
        promocodeDiscount: data.promocode_discount,
    }
}

export function updateCartCreator(data) {
    return {
        type: UPDATE_CART,
        items: data.items,
        totalPrice: data.total_price,
        showRefill: data.show_refill,
        advancePayment: data.advance_payment,
        freeDelivery: data.has_free_delivery,
        promocode: data.promocode,
        promocodeDiscount: data.promocode_discount,
    }
}

export function clearCartCreator() {
    return {
        type: CLEAR_CART
    }
}

export function loadCartItemSuitableTariffsCreator(itemId, tariffs, hasNext, page=2) {
    return {
        type: CART_ITEM_LOAD_SUITABLE_TARIFFS,
        itemId: itemId,
        tariffs: tariffs,
        hasNext: hasNext,
        page: page
    }
}


export function loadCartItemSuitableNumbersCreator(itemId, numbers, hasNext, page=2) {
    return {
        type: CART_ITEM_LOAD_SUITABLE_NUMBERS,
        itemId: itemId,
        numbers: numbers,
        hasNext: hasNext,
        page: page
    }
}


/**
 * @param {*} action INIT_CART или UPDATE_CART.
 */
function initOrUpdateCart(state, action) {
    let suitableTariffs = {},
        suitableNumbers = {};
    action.items.map(function (item) {
        suitableTariffs[item.id] = {};
        suitableTariffs[item.id]['data'] = item.suitable_tariffs;
        suitableTariffs[item.id]['hasNext'] = item.tariffs_has_next;
        suitableTariffs[item.id]['page'] = 2;
        suitableNumbers[item.id] = {};
        suitableNumbers[item.id]['data'] = item.suitable_numbers;
        suitableNumbers[item.id]['hasNext'] = item.numbers_has_next;
        suitableNumbers[item.id]['page'] = 2;
    });
    let itemsCount = 0;
    action.items.forEach((item) => {
        if (item.number) itemsCount += 1;
        if (item.tariff) itemsCount += 1;
        if (item.device) itemsCount += 1;
    });
    let cartDevices = action.items.map(function (item) {
        if (item.device) {
            return item.device.id
        }
    });
    return Object.assign({}, state, {
        hasLoaded: true,
        items: action.items,
        totalPrice: action.totalPrice,
        itemsCount: itemsCount,
        cartNumbers: action.items.map(function (item) {
            if (item.number) {
                return item.number.id
            }
        }),
        cartTariffs: action.items.map(function (item) {
            if (item.tariff) {
                return item.tariff.id
            }
        }),
        cartDevices: cartDevices,
        relatedDevices: state.relatedDevices,
        isRelatedDevicesInCart: state.relatedDevices.every((element, index, array) => {
            return cartDevices.indexOf(element) !== -1
        }),
        suitableTariffs: suitableTariffs,
        suitableNumbers: suitableNumbers,
        showRefill: action.showRefill,
        refillItems: action.items.filter(function (item) {
            return item.number && item.number.is_refill_allowed;
        }),
        advancePayment: action.advancePayment,
        freeDelivery: action.freeDelivery,
        promocode: action.promocode,
        promocodeDiscount: action.promocodeDiscount,
    });
}


export function cartReducer(state = initialCartState, action) {
    switch (action.type) {
        case INIT_CART: {
            return initOrUpdateCart(state, action);
        }
        case UPDATE_CART: {
            let wasEmpty = state.items.length === 0;
            let willBeEmpty = action.items.length === 0;

            let becomesEmpty = !wasEmpty && willBeEmpty;
            let becomesUnempty = wasEmpty && !willBeEmpty;

            if (IS_CART_PAGE && (becomesEmpty || becomesUnempty)) {
                location.reload();
                return state;
            }

            return initOrUpdateCart(state, action);
        }
        case INIT_EMPTY_CART: {
            return Object.assign({}, state, {
                hasLoaded: true,
                items: [],
                totalPrice: "0",
                itemsCount: 0,
                cartNumbers: [],
                cartTariffs: [],
                cartDevices: [],
                relatedDevices: state.relatedDevices,
                isRelatedDevicesInCart: false,
                showRefill: false,
                refillItems: [],
                advancePayment: "0",
                freeDelivery: false,
                promocode: null,
                promocodeDiscount: null,
            });
        }
        case CLEAR_CART: {
            if (IS_CART_PAGE) {
                location.reload();
                return state;
            }

            return Object.assign({}, state, {
                items: [],
                totalPrice: "0",
                itemsCount: 0,
                cartNumbers: [],
                cartTariffs: [],
                cartDevices: [],
                relatedDevices: state.relatedDevices,
                isRelatedDevicesInCart: false,
                showRefill: false,
                refillItems: [],
                advancePayment: "0",
                freeDelivery: false,
                promocode: null,
                promocodeDiscount: null,
            });
        }
        case CART_ITEM_LOAD_SUITABLE_TARIFFS: {
            let newSuitableTariffs = Object.assign({}, state.suitableTariffs);
            if (action.itemId in state.suitableTariffs) {
                action.tariffs.forEach((tariff, i) => {
                    newSuitableTariffs[action.itemId]['data'].push(tariff);
                });
                newSuitableTariffs[action.itemId]['hasNext'] = action.hasNext;
                newSuitableTariffs[action.itemId]['page'] = action.page;
            } else {
                newSuitableTariffs[action.itemId]['data'] = action.tariffs;
                newSuitableTariffs[action.itemId]['hasNext'] = action.hasNext;
                newSuitableTariffs[action.itemId]['page'] = action.page;
            }
            return {...state, suitableTariffs: newSuitableTariffs}
        }
        case CART_ITEM_LOAD_SUITABLE_NUMBERS: {
            let newSuitableNumbers = Object.assign({}, state.suitableNumbers);
            if (action.itemId in state.suitableNumbers) {
                action.numbers.forEach((number, i) => {
                    newSuitableNumbers[action.itemId]['data'].push(number);
                });
                newSuitableNumbers[action.itemId]['hasNext'] = action.hasNext;
                newSuitableNumbers[action.itemId]['page'] = action.page + 1;
            } else {
                newSuitableNumbers[action.itemId]['data'] = action.numbers;
                newSuitableNumbers[action.itemId]['hasNext'] = action.hasNext;
                newSuitableNumbers[action.itemId]['page'] = action.page + 1;
            }
            return {...state, suitableNumbers: newSuitableNumbers}
        }
        default:
            return state
    }
}
