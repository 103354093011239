import React from "react";
import { currencyPropTypes } from "./prop_types";

export default function CurrencySymbol(props) {
  let currency = props.currency || CURRENCY;
  return <span dangerouslySetInnerHTML={{ __html: currency.site_symbol }} />;
}

CurrencySymbol.propTypes = {
  currency: currencyPropTypes
};
