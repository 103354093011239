export default function initPaymentButtonsContainer() {
  let elements = document.querySelectorAll(".payment-buttons-container");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    init(element);
  }
}

function init(baseElement) {
  let showAllButton = baseElement.querySelector(
    ".payment-buttons-container__show-all-button"
  );
  if (showAllButton) {
    showAllButton.addEventListener("click", function() {
      showAllPaymentButtons(baseElement);
    });
  }
}

function showAllPaymentButtons(baseElement) {
  let paymentButtons = baseElement.querySelectorAll(".payment-button");
  for (let i = 0; i < paymentButtons.length; i++) {
    const button = paymentButtons[i];
    button.style.display = "";
  }

  let showAllButton = baseElement.querySelector(
    ".payment-buttons-container__show-all-button"
  );
  if (showAllButton) {
    showAllButton.style.display = "none";
  }
}
