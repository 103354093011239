import "../css/index.scss";

import { initSentry } from "./utils/sentry";
initSentry();

import "core-js/features/url";
import "core-js/features/dom-collections/for-each";
import "core-js/features/object/assign";
import "core-js/features/promise";
import "core-js/features/weak-set";

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
require("jquery-countdown");
require("jquery-ui/ui/widgets/datepicker");
window.fancybox = require("fancybox")($);
require("perfect-scrollbar/jquery")($);
require("jquery-mask-plugin");
require("jquery-sticky");
require("form-serializer");
require("sticky-kit/dist/sticky-kit");
import "suggestions-jquery";

import toastr from "toastr";
toastr.options.escapeHtml = true;
toastr.options.closeButton = true;
toastr.options.positionClass = "toast-responsive-position";

import "./components/misc/seohide";
import initDatepicker from "./components/forms/inputs/datepicker";
import { initNumberSelectionModal } from "./components/forms/number_selection_modal";
import { initTariffSelectionModal } from "./components/forms/tariff_selection_modal";
import { initCallBackModal } from "./components/forms/call_back_modal";
import { initCountdownTimers } from "./components/text/countdown_timer";
import { initArticles } from "./components/publications/articles";
import { initContacts } from "./components/forms/contacts";
import { initCooperation } from "./components/forms/cooperation";
import { initSellNumber } from "./components/forms/sell-number";
import { initFeedbackForm } from "./components/forms/feedback";
import { initNews } from "./components/publications/news";
import { initReview } from "./components/publications/reviews";
import { initStocks } from "./components/publications/stocks";
import { initCart } from "./react/modules/cart";
import initSerializeJsonPlugin from "./utils/serialize-to-json";
import initTooltipster from "./components/messages/tooltipster";
import { initSubdomains } from "./react/modules/subdomains";
import { initPhoneHighlight } from "./components/contacts/phone";
import { initBuyNumberOrderForm } from "./components/forms/order_number_form";
import GenericSearcher from "./components/misc/generic-searcher";
import ShortLinkGenerator from "./components/misc/short-link-generator";
import initCompactNumbersFilter from "./components/forms/containers/compact-numbers-filter";
import initCertificateSlider from "./components/swipers/certificate-slider";
import initAuthorArticles from "./components/publications/author-articles";
import initAddToTariffComparison from "./components/tariffs/add-to-tariff-comparison";
import initClearTariffComparison from "./components/tariffs/clear-tariff-comparison";
import initComparisonTable from "./components/comparison/comparison-table";
import initScrollHint from "./components/messages/scroll-hint";
import initTabbed from "./components/sections/tabbed";
import initMenuCollapse from "./components/sections/menu-collapse";
import initCardSlider from "./components/swipers/card-slider";
import initInfoSlider from "./components/swipers/info-slider";
import initInfoAccordion from "./components/sections/info-accordion";
import initInfoAccordionToTabbed from "./components/sections/info-accordion-to-tabbed";
import initProviderCoverageMap from "./components/misc/provider-coverage-map";
import initPillCheckbox from "./components/forms/inputs/pill-checkbox";
import initProductGallery from "./components/devices/product-gallery";
import initUnderlineIconButton from "./components/buttons/underline-icon-button";
import initStockRequest from "./components/forms/stock-request";
import initMainSlider from "./components/swipers/main-slider";
import initAttachmentCard from "./components/cards/attachment-card";
import initPaymentButton from "./components/orders/payment-button";
import initPaymentButtonsContainer from "./components/orders/payment-buttons-container";
import initToggler from "./components/sections/toggler";
import initCheckboxHorizontalItem from "./components/forms/inputs/checkbox-horizontal-item";
import initLargePicker from "./components/sections/large-picker";
import initSliderAuto from "./components/swipers/slider-auto";
import { initYandexShareLazyLoad } from "./components/misc/yandex-share-lazy-load";
import initGTM from "./utils/gtm";
import initPromocodeQueryLoader from "./components/orders/promocode-query-loader";
import initConversionOnLeaving from "./components/modals/conversion-on-leaving";
import initEmailSuggestions from "./components/forms/inputs/email-suggestions";
import initSuccessModal from "./components/modals/success-modal";
import initFooterDisclaimer from "./components/nav/footer-disclaimer";
import initNiceSelect from "./components/forms/inputs/nice-select";
import initCheckboxSelectorAny from "./components/forms/inputs/checkbox-selector-any";
import initRangeInputGroup from "./components/forms/groups/range-input-group";
import initCallsDirectionSlider from "./components/tariffs/calls-direction-slider";
import initInternetRangeInputGroup from "./components/tariffs/internet-range-input-group";
import initTariffCalculator from "./components/tariffs/tariff-calculator";
import initScrollToTop from "./components/misc/scroll-to-top";
import initScrollToEnd from "./components/misc/init-scroll-to-end";
import initCartButton from "./components/buttons/cart-button";
import initHeaderMenu from "./components/nav/header-menu";
import initHeader from "./components/nav/header";
import initHeaderSearchBar from "./components/nav/header-search-bar";
import initFooterMenu from "./components/nav/footer-menu";
import {initCatalogViewNumbersCatalog, initCatalogViewNumbersListing, initCatalogViewNumbersErrorPage} from "./components/catalog-view/numbers";
import initSidebarDrawerCard from "./components/cards/sidebar-drawer-card";
import initCatalogFilterGroupModule from "./components/forms/groups/catalog-filter-group";
import initCatalogFilterGroupCounterModule from "./components/forms/groups/catalog-filter-group-counter";
import initCatalogViewNumberSetCatalog from "./components/catalog-view/number-sets";
import initCheckboxSelectorExclusiveModule from "./components/forms/inputs/checkbox-selector-exclusive";
import initMasksModule from "./components/forms/inputs/masks";
import initQuickFillModule from "./components/forms/utils/quick-fill";
import {initCatalogViewTariffsCatalog, initCatalogViewTariffsListing} from "./components/catalog-view/tariffs";
import initTariffsConditionsModule from "./components/tariffs/tariffs-conditions";
import {initCatalogViewDevicesCatalog, initCatalogViewDevicesLanding} from "./components/catalog-view/devices";
import {initCatalogViewStarNumbersCatalog, initCatalogViewStarNumbersIndex} from "./components/catalog-view/star-numbers";
import initInternetReasonsModule from "./components/tariffs/internet-reasons";
import initAutoMdSwiperModule from "./components/swipers/auto-md-swiper";
import initShadeSwiperModule from "./components/swipers/shade-swiper";
import {initModalScrollbars} from "./utils/modals";
import initDragToScroll from "./components/misc/drag-to-scroll";
import initGalleryWithThumbs from "./components/swipers/gallery-with-thumbs";
import { lazyLoadJqueryValidation, whenVisible } from "./components/lazy-load";
import {initOrder} from "./components/orders/checkout";
import {initGrayCollapseListModule} from "./components/sections/gray-collapse-list";

initSerializeJsonPlugin($);
initYandexShareLazyLoad();
initGTM();

$(function() {
  initNumberSelectionModal();
  initTariffSelectionModal();
  initCallBackModal();
  initCountdownTimers();
  initArticles();
  initContacts();
  initCooperation();
  initSellNumber();
  initFeedbackForm();
  initNews();
  initReview();
  initStocks();
  initDatepicker();
  initCart();
  new GenericSearcher($("#search-form-page"));
  initTooltipster();
  initSubdomains();
  initPhoneHighlight();
  initBuyNumberOrderForm();
  initCompactNumbersFilter();
  initCardSlider();
  initCertificateSlider();
  initAuthorArticles();
  initAddToTariffComparison();
  initClearTariffComparison();
  initComparisonTable();
  initScrollHint();
  initTabbed();
  initPillCheckbox();
  initMenuCollapse();
  initInfoSlider();
  initInfoAccordion();
  initInfoAccordionToTabbed();
  initProviderCoverageMap();
  initProductGallery();
  initMainSlider();
  initUnderlineIconButton();
  initStockRequest();
  initAttachmentCard();
  initPaymentButton();
  initPaymentButtonsContainer();
  initToggler();
  initCheckboxHorizontalItem();
  initLargePicker();
  initSliderAuto();
  initPromocodeQueryLoader();
  initConversionOnLeaving();
  initEmailSuggestions();
  initSuccessModal();
  initFooterDisclaimer();
  initNiceSelect();
  initCheckboxSelectorAny();
  initRangeInputGroup();
  initCallsDirectionSlider();
  initInternetRangeInputGroup();
  initTariffCalculator();
  initScrollToTop();
  initScrollToEnd();
  initCartButton();
  initHeaderMenu();
  initHeader();
  initHeaderSearchBar();
  initFooterMenu();
  initCatalogViewNumbersCatalog();
  initCatalogViewNumberSetCatalog();
  initSidebarDrawerCard();
  initCatalogFilterGroupModule();
  initCatalogFilterGroupCounterModule();
  initCheckboxSelectorExclusiveModule();
  initMasksModule();
  initQuickFillModule();
  initCatalogViewTariffsCatalog();
  initTariffsConditionsModule();
  initCatalogViewDevicesCatalog();
  initCatalogViewDevicesLanding();
  initCatalogViewStarNumbersCatalog();
  initCatalogViewStarNumbersIndex();
  initInternetReasonsModule();
  initAutoMdSwiperModule();
  initShadeSwiperModule();
  initCatalogViewNumbersListing();
  initCatalogViewTariffsListing();
  initModalScrollbars();
  initDragToScroll();
  initGalleryWithThumbs();
  initOrder();
  initGrayCollapseListModule();
  initCatalogViewNumbersErrorPage();

  $(".short-link-generator").each((index, htmlElement) => {
    new ShortLinkGenerator($(htmlElement));
  });
  whenVisible($(".form"), async () => {
    await lazyLoadJqueryValidation();
    $(".form").validate();
  });
});
