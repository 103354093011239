import deviceTemplate from "../../../templates/device_card.ejs";
import {store} from "../../react/store";
import {renderNoResultsMessage} from "../templates";
import initCatalogView, {override} from "./core";
import extraSearchParams from "./plugins/extra-search-params";
import loadMore from "./plugins/load-more";
import sortSelect from "./plugins/sort-select";
import filters from "./plugins/filters";
import dataLayer from "./plugins/data-layer";
import transformParams from "./plugins/transform-params";
import {cleanMaskedInteger} from "../forms/inputs/masks";
import lodashTemplate from "lodash/template";
import forEach from "lodash/forEach";
import {pushDevicesApiDataLayer} from "../../utils/gtm";

function renderDevice(device) {
  const render = lodashTemplate(deviceTemplate, {imports: {forEach: forEach}});
  const cartDevices = store.getState().cart.cartDevices;
  return render({
    device: device,
    cartDevices: cartDevices,
  });
}

function renderDevicePage(devices) {
  if (devices.length === 0) {
    return renderNoResultsMessage();
  }

  const content = devices.map(renderDevice).join("");
  return `<div class="device-card-rows">${content}</div>`;
}

const transformMaskedParams = transformParams({
  min_price: v => cleanMaskedInteger(v),
  max_price: v => cleanMaskedInteger(v),
});

export function initCatalogViewDevicesCatalog() {
  const elements = document.querySelectorAll(".js-catalog-view--devices-catalog");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    initCatalogView(element, {
      renderPage: renderDevicePage,
      plugins: [
        extraSearchParams,
        loadMore,
        sortSelect,
        filters,
        transformMaskedParams,
        dataLayer({pushToDataLayer: options => pushDevicesApiDataLayer(options)}),
      ],
    });
  }
}

function hideLandingUi(view) {
  view.reload = override(view.reload, (callSuper, extraParams) => {
    const landingUi = view.element.querySelectorAll(".js-catalog-view__devices-landing-ui");
    for (const element of landingUi) {
      element.style.display = "none";
    }

    const catalogUi = view.element.querySelectorAll(".js-catalog-view__devices-catalog-ui");
    for (const element of catalogUi) {
      element.style.display = "";
    }

    return callSuper(extraParams);
  });
  return view;
}

export function initCatalogViewDevicesLanding() {
  const elements = document.querySelectorAll(".js-catalog-view--devices-landing");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    initCatalogView(element, {
      renderPage: renderDevicePage,
      plugins: [
        extraSearchParams,
        loadMore,
        sortSelect,
        filters,
        transformMaskedParams,
        hideLandingUi,
        dataLayer({pushToDataLayer: options => pushDevicesApiDataLayer(options)}),
      ],
    });
  }
}
