import { lazyLoadSlick } from "../lazy-load";

export default async function initSliderAuto() {
  if ($(".slider-auto").length === 0) return;

  await lazyLoadSlick();

  $(".slider-auto").slick({
    dots: true,
    speed: 300,
    variableWidth: true,
    useTransform: true,
    touchThreshold: 20,
    infinite: true,
    centerMode: true,
    arrows: false,
  });
}
