import React from "react";

export default function OrderEmptyMessage() {
  return (
    <div className="empty-message">
      <div className="empty-message__icon empty-message__icon--zero empty-message__icon--opacity--10" />
      <h2 className="empty-message__text">Ваша корзина пуста</h2>
    </div>
  );
}
