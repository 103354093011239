export const UPDATE_CHECKBOX_HORIZONTAL_ITEM_EVENT =
  "update.checkbox-horizontal-item";

function isUpdateEvent(event) {
  return (
    event.type === "update" && event.namespace === "checkbox-horizontal-item"
  );
}

export default function initCheckboxHorizontalItem() {
  $(".checkbox-horizontal-item").on(
    ["click", "change", UPDATE_CHECKBOX_HORIZONTAL_ITEM_EVENT].join(" "),
    function(event) {
      var $input = $(this).find("input");
      var name = $input.attr("name");

      if ($input.is(":checked")) {
        $(this).addClass("checkbox-horizontal-item--active");
      } else {
        $(this).removeClass("checkbox-horizontal-item--active");
      }

      // Обновляем другие checkbox-horizontal-item с радио-кнопками
      if (name && !isUpdateEvent(event)) {
        $('input[name="' + name + '"]')
          .closest(".checkbox-horizontal-item")
          .trigger(UPDATE_CHECKBOX_HORIZONTAL_ITEM_EVENT);
      }
    }
  );

  $(".checkbox-horizontal-item")
    .filter((ix, item) =>
      $(item)
        .find("input")
        .is(":checked")
    )
    .addClass("checkbox-horizontal-item--active");
}
