import * as Sentry from "@sentry/browser";

/**
 * Содержит ли событие "мусорное исключение" - т.е. исключение,
 * содержащее только фреймы, ссылающееся на неизвестные файлы.
 * Такие исключения выбрасываются чужими скриптами, не связанными с нашим кодом.
 * Идея взята с: https://github.com/getsentry/sentry-javascript/issues/3977
 */
function isJunkException(event) {
  if (!event.exception) {
    return false;
  }

  if (event.exception.values.length < 1) {
    return false;
  }

  let exception = event.exception.values[0];
  if (!exception.stacktrace) {
    return false;
  }

  let frames = exception.stacktrace.frames;
  if (!frames) {
    return false;
  }

  for (let i = 0; i < frames.length; i++) {
    const frame = frames[i];
    if (
      frame.filename &&
      frame.filename !== "<anonymous>" &&
      frame.filename !== "[native code]"
    ) {
      return false;
    }
  }
  return true;
}

/**
 * Является ли событие неправильной обработкой ошибки загрузки внешнего скрипта в промисе.
 * Пример кода, генерирующего такое событие:
 *
 *   new Promise((resolve, reject) => {
 *     let s = document.createElement("script");
 *     s.src = "http://example.com/fails-to-load.js";
 *     s.onload = resolve;
 *     s.onerror = reject;
 *     // Правильная обработка:
 *     // s.onerror = (event) => { reject(new Error("fail")) };
 *     document.head.appendChild(s);
 *   });
 *
 * В нашем коде таких ошибок нет.
 */
function isScriptErrorEventRejection(event) {
  if (!event.exception) {
    return false;
  }

  if (event.exception.values.length < 1) {
    return false;
  }

  let exception = event.exception.values[0];
  if (
    /Non-Error promise rejection captured with keys/.test(exception.value) &&
    event.extra &&
    event.extra.__serialized__ &&
    /head > script/.test(event.extra.__serialized__.target) &&
    event.extra.__serialized__.type === "error"
  ) {
    return true;
  }
  return false;
}

export function initSentry() {
  if (window.sentry_dsn !== "") {
    Sentry.init({
      dsn: window.sentry_dsn,
      environment: window.sentry_environment,
      release: window.sentry_release,
      beforeSend: function(event, hint) {
        if (isJunkException(event)) {
          return null;
        }

        if (isScriptErrorEventRejection(event)) {
          return null;
        }

        return event;
      },
      denyUrls: [
        // Ошибки NS_ERROR_NOT_INITIALIZED из неизвестного скрипта в Firefox
        /injectedScript/,
        /widgets\.mango-office\.ru/
      ]
    });
  }

  var testJSError = document.getElementById("test-js-error");
  if (testJSError) {
    testJSError.addEventListener("click", function(event) {
      event.preventDefault();
      throw new Error("Исключение JS вызванное вручную");
    });
  }
}
