import { combineReducers } from "redux";

import { searchReducer, initialSearchState } from "../modules/search";
import { orderReducer, initialOrderState } from "../modules/order";
import { cartReducer, initialCartState } from "./cart";
import { subdomainReducer } from "../modules/subdomainReducer";

export const initialState = {
  cart: initialCartState.cart,
  order: initialOrderState.order,
  search: initialSearchState.search
};

export const reducer = combineReducers({
  search: searchReducer,
  order: orderReducer,
  cart: cartReducer,
  subdomainState: subdomainReducer
});
