export default function initPillCheckbox() {
  $(".pill-checkbox").on("click", function() {
    $(this).toggleClass("pill-checkbox--active");

    let input = $(this).find(".pill-checkbox__input");
    input.prop("disabled", function(i, v) {
      return !v;
    });
    input.trigger("change");
  });
}
