class LargePicker {
  constructor(baseElement) {
    this.baseElement = baseElement;
    this.headerButtons.forEach(button => {
      button.addEventListener("click", event => {
        event.preventDefault();
        this.handleHeaderButtonClick(button);
      });
    });
  }

  get headerButtons() {
    let elements = this.baseElement.querySelectorAll(
      ".large-picker__header-button"
    );
    return Array.prototype.slice.call(elements);
  }

  get forms() {
    let elements = this.baseElement.querySelectorAll(".large-picker__number-form, .large-picker__tariff-form");
    return Array.prototype.slice.call(elements);
  }

  handleHeaderButtonClick(button) {
    let buttonType = undefined;
    if (button.classList.contains("large-picker__header-button--tariff")) {
      buttonType = "tariff";
    } else if (
      button.classList.contains("large-picker__header-button--number")
    ) {
      buttonType = "number";
    }

    for (const otherButton of this.headerButtons) {
      let otherButtonType = undefined;
      if (
        otherButton.classList.contains("large-picker__header-button--tariff")
      ) {
        otherButtonType = "tariff";
      } else if (
        otherButton.classList.contains("large-picker__header-button--number")
      ) {
        otherButtonType = "number";
      }

      if (otherButtonType && otherButtonType == buttonType) {
        otherButton.classList.add("large-picker__header-button--active");
      } else {
        otherButton.classList.remove("large-picker__header-button--active");
      }
    }

    for (const form of this.forms) {
      let formType = undefined;
      if (form.classList.contains("large-picker__tariff-form")) {
        formType = "tariff";
      } else if (form.classList.contains("large-picker__number-form")) {
        formType = "number";
      }

      if (formType && formType == buttonType) {
        $(form).fadeIn("fast");
      } else {
        $(form).hide();
      }
    }
  }
}

export default function initLargePicker() {
  let elements = document.querySelectorAll(".large-picker");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    new LargePicker(element);
  }
}
