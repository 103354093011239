import throttle from "lodash/throttle";

export default function initShadeSwiperModule() {
  // Не выполняем ленивую загрузку slick, чтобы выполниться до инициализации всех свайперов.
  $(".shade-swiper").on("init", (event, slick) => {
    updateSlider(event.target, slick);
    slick.$slider.on("swipe", (event, slick) => {
      updateSlider(event.target, slick);
    });

    const updateThrottled = throttle(() => {
      updateSlider(event.target, slick);
    }, 1000);
    window.addEventListener("resize", updateThrottled);
  });
}

function updateSlider(element, slick) {
  const infinite = slick.slickGetOption("infinite");
  const slidesToShow = slick.slickGetOption("slidesToShow");
  // Slick отключается, если нечего скроллить
  const disabled = slidesToShow >= slick.slideCount;

  const shadeLeft = element.hasAttribute("data-shade-left");
  if (shadeLeft && !disabled) {
    if (slick.currentSlide === 0 && !infinite) {
      element.classList.remove("shade-swiper--shade-left");
    } else {
      element.classList.add("shade-swiper--shade-left");
    }
  } else {
    element.classList.remove("shade-swiper--shade-left");
  }

  const shadeRight = element.hasAttribute("data-shade-right");
  if (shadeRight && !disabled) {
    if (slick.currentSlide === (slick.slideCount - 1) && !infinite) {
      element.classList.remove("shade-swiper--shade-right");
    } else {
      element.classList.add("shade-swiper--shade-right");
    }
  } else {
      element.classList.remove("shade-swiper--shade-right");
  }
}
