function initTariffsConditions(element) {
  const items = element.querySelectorAll(".tariffs-conditions__item");
  for (const item of items) {
    const input = item.querySelector("input");

    item.addEventListener("click", () => {
      input.disabled = !input.disabled;
      input.dispatchEvent(new Event("change", {bubbles: true}));
    });

    input.addEventListener("change", () => {
      if (input.disabled) {
        item.classList.remove("tariffs-conditions__item--active");
      } else {
        item.classList.add("tariffs-conditions__item--active");
      }
    });
  }
}

export default function initTariffsConditionsModule() {
  const elements = document.querySelectorAll(".tariffs-conditions");
  for (const element of elements) {
    initTariffsConditions(element);
  }
}
