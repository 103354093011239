export default function initUnderlineIconButton() {
  let buttons = document.querySelectorAll(".underline-icon-button");
  for (let i = 0; i < buttons.length; i++) {
    const element = buttons[i];
    if (element.hasAttribute("data-disable-js")) continue;

    element.addEventListener("click", () => {
      let input = element.querySelector(".underline-icon-button__input");
      if (!input) {
        return;
      }

      if (input.disabled) {
        input.disabled = false;
        element.classList.add("underline-icon-button--active");
      } else {
        input.disabled = true;
        element.classList.remove("underline-icon-button--active");
      }

      input.dispatchEvent(new Event("change", { bubbles: true }));
    });
  }
}
