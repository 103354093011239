import {override} from "../core";

export default function transformParams(transforms) {
  return view => {
    view.getSearchParams = override(view.getSearchParams, callSuper => {
      const params = callSuper();
      params.forEach((value, key) => {
        if (transforms[key]) {
          params.set(key, transforms[key](value));
        }
      });
      return params;
    });

    return view;
  };
}
