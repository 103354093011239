import {
  addSpinnerOnFormBtn,
  removeSpinnerOnFormBtn,
  prepareFormErrors
} from "../../utils/utils";
import Cookies from "js-cookie";
import { mapErrorsToForm, showSuccessFancybox } from "../../utils/modals";
import { UPDATE_CHECKBOX_HORIZONTAL_ITEM_EVENT } from "./inputs/checkbox-horizontal-item";
import { lazyLoadJqueryValidation } from "../lazy-load";
import { disableConversionOnLeaving } from "../modals/conversion-on-leaving";

export default class GenericFlatForm {
  constructor(htmlElement, validator) {
    let baseElement = $(htmlElement);
    this.baseElement = baseElement;

    let url = baseElement.attr("action");
    if (!url) return;
    this.url = url;

    this.buttonText = this.button.text().trim();
    this.initValidation(validator);
    baseElement.on("submit", e => this.buttonEvent(e));
  }

  async initValidation(validator) {
    await lazyLoadJqueryValidation();
    this.elementValidator = this.baseElement.validate(validator);
  }

  get validatorHasInit() {
    return !!this.elementValidator;
  }

  buttonEvent(e) {
    if (!this.validatorHasInit) return;

    e.preventDefault();

    if (this.baseElement.valid()) {
      let data = new FormData(this.baseElement[0]);
      data.append("ajax", "1");
      addSpinnerOnFormBtn(this.button);
      this.sendAjax(this.url, data)
        .done(response => this.doneRequest(response))
        .fail(response => this.failRequest(response));
    }
  }

  resetForm() {
    this.baseElement[0].reset();
  }

  doneRequest(response) {
    if (response.status === "ok") {
      this.resetForm();
      this.baseElement
        .find(".checkbox-horizontal-item")
        .trigger(UPDATE_CHECKBOX_HORIZONTAL_ITEM_EVENT);
      removeSpinnerOnFormBtn(this.button, this.buttonText);
      showSuccessFancybox();
      disableConversionOnLeaving();
    } else {
      let errors = prepareFormErrors(response.errors);
      mapErrorsToForm(errors, this.elementValidator);
      removeSpinnerOnFormBtn(this.button, this.buttonText);
    }
  }

  failRequest(response) {
    console.log(response);
  }

  get button() {
    return this.baseElement.find(".button");
  }

  sendAjax(url, data) {
    return $.ajax({
      url: url,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken")
      },
      method: "POST",
      processData: false,
      contentType: false,
      dataType: "json",
      data: data
    });
  }
}
