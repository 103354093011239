function moveJivositeButtonUp(offset) {
  let styleElement = document.createElement("style");
  styleElement.innerHTML = `
    .__jivoMobileButton {
      bottom: ${offset}px !important;
    }
  `;
  document.head.appendChild(styleElement);
}

function moveFixedElementsUp() {
  let footerDisclaimer = document.querySelector(".footer-disclaimer");
  if (!footerDisclaimer) {
    return;
  }

  let disclaimerHeight = footerDisclaimer.getBoundingClientRect().height;
  moveJivositeButtonUp(disclaimerHeight);
}

export default function initFooterDisclaimer() {
  moveFixedElementsUp();
}
