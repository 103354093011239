import startsWith from "lodash/startsWith";
import Cookies from "js-cookie";
import { postToApi } from "../../utils/api";
import {
  getPrice,
  addSpinnerOnFormBtn,
  removeSpinnerOnFormBtn
} from "../../utils/utils";
import { SIM_TYPE_USIM } from "./cart";
import { PHONE_REGEXP } from "../../utils/validation_methods";
import { store } from "../store";
import { initPhoneMask } from "../../components/forms/inputs/masks";
import { lazyLoadJqueryValidation } from "../../components/lazy-load";
import { disableConversionOnLeaving } from "../../components/modals/conversion-on-leaving";

const CART_ITEMS_API_URL = "/api/cart/items/";
export const CART_ORDERS_API_URL = "/api/orders/";
export const DELIVERY_TYPE_COURIER = 1;
const CHANGE_DELIVERY_TYPE = "CHANGE_DELIVERY_TYPE";

export const initialOrderState = {
  order: {
    deliveryCost: 0
  }
};

export function changeDeliveryTypeCreator(deliveryCost) {
  return {
    type: CHANGE_DELIVERY_TYPE,
    deliveryCost: deliveryCost
  };
}

export function orderReducer(state = initialOrderState, action) {
  switch (action.type) {
    case CHANGE_DELIVERY_TYPE: {
      return Object.assign({}, state, {
        deliveryCost: action.deliveryCost
      });
    }
    default:
      return state;
  }
}

export function getSimTypes() {
  const USIM = 4;

  // При добавлении новых SIM в список нужно убедиться, что они умещаются в верстке.
  return [
    {
      id: USIM,
      name: "Универсальная",
      icon: "usim"
    }
  ];
}

function mapErrorsToForm(errors, $validator) {
  const form = $($validator.currentForm);
  if (errors.hasOwnProperty("client_phones")) {
    let nonEmptyPhoneInputs = form
      .find(".js-order-input-phone-container input")
      .filter(function() {
        return $(this).val();
      });

    for (let phoneIndex in errors.client_phones) {
      if (errors.client_phones.hasOwnProperty(phoneIndex)) {
        phoneIndex = Number(phoneIndex);
        const phoneErrors = errors.client_phones[phoneIndex];

        let newKey = nonEmptyPhoneInputs[phoneIndex].name;
        errors[newKey] = phoneErrors;
      }
    }
    delete errors["client_phones"];
  }

  if (errors.hasOwnProperty("non_field_errors")) {
    $("#cart-errors")
      .text(errors["non_field_errors"])
      .css("display", "");
    delete errors["non_field_errors"];
  } else {
    $("#cart-errors").css("display", "none");
  }

  if (
    errors.hasOwnProperty("delivery_date_from") ||
    errors.hasOwnProperty("delivery_date_to")
  ) {
    let input = form.find('input[name="desired_date"]');
    input.addClass("error");
    $(".js-delivery-time-errors").html(
      $(
        '<label id="desired_date-error" class="error" for="desired_date">Укажите дату доставки.</label>'
      )
    );
    delete errors["delivery_date_from"];
    delete errors["delivery_date_to"];
  }

  if (errors.hasOwnProperty("payment_type")) {
    let buttonsContainer = form.find('.payment-buttons-container');
    $('<label class="error">')
      .text(errors["payment_type"])
      .appendTo(buttonsContainer);
    delete errors["payment_type"];
  }

  $validator.showErrors(errors);
}

function scrollToFirstError(form) {
  let firstError = form.find(".error:visible").first();
  if (!firstError.length) return;

  if (firstError[0].scrollIntoView) {
    firstError[0].scrollIntoView({
      block: "center"
    });
  }
}

export async function initOrderCheckoutForm() {
  const form = $("#order-checkout-form"),
    btn = form.find(".js-order-checkout-button"),
    btnText = btn.text().trim();

  if (form.length === 0) return;

  form.on("submit", function(e) {
    e.preventDefault();
  });

  await lazyLoadJqueryValidation();

  const checkoutFormValidator = form.validate({
    rules: {
      client_email: {
        required: true,
        email: true
      },
      client_name: {
        required: true
      },
      client_phones_1: {
        required: true,
        regexp: PHONE_REGEXP
      },
      delivery_type: {
        required: true
      },
      city: {
        required: true
      },
      discount_card: {
        required: false,
        checkLuhn: true
      }
    }
  });

  form.find(".js-order-add-phone").click(function() {
    const inputs = form.find(".input-phone");
    let cloned = form
      .find(".input-phone")
      .first()
      .clone()
      .val("");
    let index = inputs.length + 1;
    cloned.removeClass("error");
    cloned.attr("name", "client_phones_" + index);
    initPhoneMask(cloned);
    cloned.removeClass("input-text--required");
    cloned.removeAttr("required");
    cloned.appendTo(".js-order-input-phone-container");

    let cloneContainer = $("<div>");
    cloned.appendTo(cloneContainer);
    cloneContainer.appendTo(".js-order-input-phone-container");

    $('input[name="client_phones_' + index + '"]').rules("add", {
      regexp: PHONE_REGEXP
    });
  });

  form.on("change", 'input[name="desired_date"]', function(e) {
    if ($(this).val()) {
      $("#desired_date-error").remove();
    }
  });

  form.on("change focus blur", ".input-phone", function(e) {
    let input = $(this),
      name = input.attr("name");
    if (name !== "client_phones_1") {
      if (input.val() === "") {
        input.removeClass("error");
        $("#" + name + "-error").detach();
      }
    }
  });

  function loadDeliveryCostFromRadioButton(radioButton) {
    let deliveryCost = radioButton.attr("data-delivery-cost");
    if (deliveryCost) {
      store.dispatch(changeDeliveryTypeCreator(parseFloat(deliveryCost)));
    }
  }
  form.find('input[type=radio][name="delivery_type"]').change(function(e) {
    const radioButton = $(this);
    if (radioButton.prop("checked")) {
      loadDeliveryCostFromRadioButton(radioButton);
    }
  });
  const firstRadioButton = form.find(
    'input[type=radio][name="delivery_type"]:first'
  );
  loadDeliveryCostFromRadioButton(firstRadioButton);

  form.on("submit", function(e) {
    if (form.valid()) {
      let raw_data = form.serializeObject(),
        client_phones = [];
      for (let key in raw_data) {
        if (startsWith(key, "client_phones_")) {
          if (raw_data[key]) {
            client_phones.push(raw_data[key]);
            delete raw_data[key];
          }
        }
      }

      let data = {
        cart: raw_data.cart,
        client_name: raw_data.client_name,
        client_email: raw_data.client_email,
        client_phones: client_phones,
        delivery_type: raw_data.delivery_type,
        comment: raw_data["comment_" + raw_data.delivery_type],
        flags: raw_data.flags,
        payment_type: raw_data.payment_type,
        subscribe: raw_data.subscribe
      };

      if (raw_data.discount_card !== "") {
        data.discount_card = raw_data.discount_card;
      }

      if (parseInt(raw_data.delivery_type) == DELIVERY_TYPE_COURIER) {
        data.delivery_city = raw_data.city;
        data.delivery_address = raw_data.address;
        data.delivery_date_from =
          raw_data.desired_date + " " + raw_data.desired_time_from + ":00";
        data.delivery_date_to =
          raw_data.desired_date + " " + raw_data.desired_time_to + ":00";
      }

      addSpinnerOnFormBtn(btn);
      postToApi(CART_ORDERS_API_URL, data)
        .done(data => {
          let products = [];
          data.items.forEach(item => {
            if (item.number) {
              products.push({
                id: item.number.prefixed_id,
                name: item.number.number_digit,
                price: getPrice(item.number.site_price),
                brand: item.number.provider_name,
                category: "Номер",
                quantity: 1
              });
            }
            if (item.tariff) {
              products.push({
                id: item.tariff.prefixed_id,
                name: item.tariff.name,
                price: getPrice(item.tariff.current_price),
                brand: item.tariff.provider_name,
                category: "Тариф",
                quantity: 1
              });
            }
            if (item.device) {
              products.push({
                id: item.device.prefixed_id,
                name: item.device.name,
                price: getPrice(item.device.current_price),
                brand: item.device.manufacturer_name,
                category: item.device.device_type_name,
                quantity: 1
              });
            }
          });
          dataLayer.push({
            ecommerce: {
              checkout: {
                actionField: {
                  step: 1
                },
                products: products
              }
            },
            event: "gtm-ee-event",
            "gtm-ee-event-category": "Enhanced Ecommerce",
            "gtm-ee-event-action": "Checkout Step 1",
            "gtm-ee-event-non-interaction": "False"
          });
          window.location = data.url;
          removeSpinnerOnFormBtn(btn, btnText);
          disableConversionOnLeaving();
        })
        .fail(response => {
          const errors = JSON.parse(response.responseText);
          mapErrorsToForm(errors, checkoutFormValidator);
          removeSpinnerOnFormBtn(btn, btnText);
          scrollToFirstError(form);
        });
    }
  });

  function disableOrEnableButton() {
    let state = store.getState();
    if (state.cart.items.length > 0) {
      btn.removeAttr("disabled");
    } else {
      btn.attr("disabled", "disabled");
    }
  }
  disableOrEnableButton();
  store.subscribe(disableOrEnableButton);
}

export function setCartItemNumber(itemId, number) {
  return $.ajax({
    url: CART_ITEMS_API_URL + itemId + "/",
    data: JSON.stringify({
      number: number,
      sim_type: SIM_TYPE_USIM
    }),
    method: "PUT",
    dataType: "json",
    contentType: "application/json; charset=UTF-8",
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken")
    }
  });
}

export function setCartItemTariff(itemId, tariff) {
  return $.ajax({
    url: CART_ITEMS_API_URL + itemId + "/",
    data: JSON.stringify({
      tariff: tariff
    }),
    method: "PUT",
    dataType: "json",
    contentType: "application/json; charset=UTF-8",
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken")
    }
  });
}
