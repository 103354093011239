import PropTypes from "prop-types";

export const tagPropTypes = PropTypes.shape({
  icon: PropTypes.string,
  name: PropTypes.string.isRequired
});

export const providerPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  display_name: PropTypes.string.isRequired,
  official_name: PropTypes.string.isRequired,
  icon: PropTypes.string
});

export const currencyPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  site_symbol: PropTypes.string.isRequired,
});

export const tariffPropTypes = PropTypes.shape({
  name: PropTypes.string,
  provider: PropTypes.string,
  provider_name: PropTypes.string,
  provider_object: providerPropTypes.isRequired,
  slug: PropTypes.string,
  tags: PropTypes.array,
  summary: PropTypes.object,
  advance_payment: PropTypes.string,
  currency: currencyPropTypes,
  activation_price: PropTypes.string,
  current_price: PropTypes.string,
  month_fee: PropTypes.string
});

export const tariffsCatalogPropTypes = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  slug: PropTypes.string,
  provider: PropTypes.string,
  provider_name: PropTypes.string,
  provider_object: providerPropTypes,
  absolute_url: PropTypes.string
});

export const numberPropTypes = PropTypes.shape({
  id: PropTypes.number,
  currency: currencyPropTypes,
  advance_price: PropTypes.string,
  site_price: PropTypes.string,
  rent_price: PropTypes.string,
  exit_price: PropTypes.string,
  provider: PropTypes.string,
  provider_name: PropTypes.string,
  provider_object: providerPropTypes.isRequired,
  number: PropTypes.string,
  number_digit: PropTypes.string,
  has_discount: PropTypes.bool,
  has_price_on_call: PropTypes.bool
});

export const numbersCatalogPropTypes = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  slug: PropTypes.string,
  provider: PropTypes.string,
  provider_name: PropTypes.string,
  provider_object: providerPropTypes,
  absolute_url: PropTypes.string
});

export const devicePropTypes = PropTypes.shape({
  name: PropTypes.string,
  main_image: PropTypes.string,
  absolute_url: PropTypes.string,
  short_info: PropTypes.string,
  tags: PropTypes.array,
  currency: currencyPropTypes,
  current_price: PropTypes.string
});

export const devicesCatalogPropTypes = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  slug: PropTypes.string,
  absolute_url: PropTypes.string
});

export const itemPropTypes = PropTypes.shape({
  id: PropTypes.string,
  subdomain: PropTypes.string,
  tariff: tariffPropTypes,
  number: numberPropTypes,
  sim_type: PropTypes.shape({
    id: PropTypes.number
  }),
  device: devicePropTypes,
  rent_period: PropTypes.number,
  suitable_tariffs: PropTypes.array,
  suitable_numbers: PropTypes.array,
  refill: PropTypes.string
});

export const newsPropTypes = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  slug: PropTypes.string,
  created: PropTypes.string,
  created_display: PropTypes.string,
  providers: PropTypes.array,
  main_image: PropTypes.string,
  main_image_width: PropTypes.number,
  main_image_height: PropTypes.number,
  main_image_list: PropTypes.string,
  main_image_detail: PropTypes.string,
  intro_display: PropTypes.string,
  content_display: PropTypes.string,
  date_publish_display: PropTypes.string,
  absolute_url: PropTypes.string
});

export const articlePropTypes = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  slug: PropTypes.string,
  created: PropTypes.string,
  created_display: PropTypes.string,
  providers: PropTypes.array,
  main_image: PropTypes.string,
  main_image_width: PropTypes.number,
  main_image_height: PropTypes.number,
  main_image_list: PropTypes.string,
  main_image_detail: PropTypes.string,
  intro_display: PropTypes.string,
  content_display: PropTypes.string,
  date_publish_display: PropTypes.string,
  authors: PropTypes.array,
  absolute_url: PropTypes.string
});

export const flatpagePropTypes = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  absolute_url: PropTypes.string
});

export const promocodePropTypes = PropTypes.shape({
  code: PropTypes.string.isRequired,
  discount: PropTypes.string.isRequired,
  currency: currencyPropTypes
});
