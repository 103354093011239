import {override} from "../core";

export default function dataLayer(options) {
  return view => {
    view.getDataLayerList = () => {
      return view.element.getAttribute("data-layer-list") || "";
    };

    view.request = override(view.request, async (callSuper, extraParams) => {
      const resp = await callSuper(extraParams);
      options.pushToDataLayer({
        items: resp.results,
        list: view.getDataLayerList(),
      });
      return resp;
    });

    return view;
  }
}
