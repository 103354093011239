/**
 * Включает на элементах .drag-to-scroll скроллинг курсором мыши.
 */

export class DragToScroll {
  constructor(baseElement) {
    this.baseElement = baseElement;

    this.isDragging = false;
    // Позиция курсора мыши на момент зажатия кнопки мыши
    this.startX = 0;
    this.startY = 0;
    // Скроллинг элемента на момент зажатия кнопки мыши
    this.startLeft = 0;
    this.startTop = 0;

    this.baseElement.addEventListener("mousedown", event => {
      if (event.button != 0) return;
      this.startDragging({ x: event.clientX, y: event.clientY });
    });
    this.baseElement.addEventListener("mousemove", event => {
      if (this.isDragging) {
        this.updateScroll({ x: event.clientX, y: event.clientY });
      }
    });
    document.addEventListener("mouseup", () => {
      this.stopDragging();
    });
    document.addEventListener("dragstart", () => {
      this.stopDragging();
    });
  }

  startDragging({ x, y }) {
    this.isDragging = true;
    this.startX = x;
    this.startY = y;
    this.startLeft = this.baseElement.scrollLeft;
    this.startTop = this.baseElement.scrollTop;
  }

  updateScroll({ x, y }) {
    let deltaX = x - this.startX;
    this.baseElement.scrollLeft = this.startLeft - deltaX;

    let deltaY = y - this.startY;
    this.baseElement.scrollTop = this.startTop - deltaY;
  }

  stopDragging() {
    this.isDragging = false;
  }
}

export default function initDragToScroll() {
  document.querySelectorAll(".drag-to-scroll").forEach(element => {
    new DragToScroll(element);
  });
}
