export default function initSerializeJsonPlugin($) {
  $.fn.serializeJSON = function() {
    let json = {},
      array = this.serializeArray();

    $.each(array, function() {
      if (json[this.name] !== undefined) {
        if (!json[this.name].push) {
          json[this.name] = [json[this.name]];
        }
        json[this.name].push(this.value || "");
      } else {
        json[this.name] = this.value;
      }
    });

    return json;
  };
}
