class ProductGallery {
  constructor(baseElement) {
    this.baseElement = baseElement;

    for (const thumb of this.thumbs) {
      thumb.addEventListener("click", event => {
        event.preventDefault();
        this.activateThumb(thumb);
      });
    }
  }

  get thumbs() {
    let nodeList = this.baseElement.querySelectorAll(".product-gallery__thumb");
    return Array.prototype.slice.apply(nodeList);
  }

  get coverImage() {
    return this.baseElement.querySelector(".product-gallery__cover-image");
  }

  activateThumb(targetThumb) {
    let targetImageUrl = targetThumb.getAttribute("href");
    if (!targetImageUrl) return;

    if (!this.coverImage) return;
    this.coverImage.setAttribute("src", targetImageUrl);

    for (const thumb of this.thumbs) {
      thumb.classList.remove("product-gallery__thumb--active");
    }
    targetThumb.classList.add("product-gallery__thumb--active");
  }
}

export default function initProductGallery() {
  let elements = document.querySelectorAll(".product-gallery");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    new ProductGallery(element);
  }
}
