export default function initSidebarDrawerCard() {
  const togglers = document.querySelectorAll(".sidebar-drawer-card-toggler");
  for (let i = 0; i < togglers.length; i++) {
    const toggler = togglers[i];
    toggler.addEventListener("click", () => {
      document.body.classList.toggle("sidebar-drawer-card-active");
    });
  }

  document.addEventListener("click", event => {
    closeOnClick(event.target);
  });
}

function closeOnClick(target) {
  if (!document.body.classList.contains("sidebar-drawer-card-active")) {
    return;
  }

  const isCard = target.closest(".sidebar-drawer-card") !== null;
  const isToggler = target.closest(".sidebar-drawer-card-toggler") !== null;
  if (isCard || isToggler) {
    return;
  }

  document.body.classList.remove("sidebar-drawer-card-active");
}
