import { UPDATE_CHECKBOX_HORIZONTAL_ITEM_EVENT } from "./checkbox-horizontal-item";

class CheckboxSelectorAny {
  constructor(baseElement) {
    this.baseElement = baseElement;

    this.baseElement.addEventListener("change", event => {
      this.onChange(event.target);
    });

    this.setChecked(this.anyInput, this.valueInputs.every(vi => !vi.checked));
  }

  get anyInput() {
    return this.baseElement.querySelector(".checkbox-selector-any__any");
  }

  get valueInputs() {
    const inputs = this.baseElement.querySelectorAll(
      "input:not(.checkbox-selector-any__any)"
    );
    // querySelectorAll возвращает NodeList, у которого есть не все API массива.
    const inputsArray = [];
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      inputsArray.push(input);
    }
    return inputsArray;
  }

  isAny(input) {
    return input.classList.contains("checkbox-selector-any__any");
  }

  setChecked(input, checked) {
    input.checked = checked;
    $(input).trigger(UPDATE_CHECKBOX_HORIZONTAL_ITEM_EVENT);
  }

  onChange(input) {
    if (this.isAny(input)) {
      this.valueInputs.forEach(vi => {
        this.setChecked(vi, !input.checked);
      });
    } else {
      if (input.checked) {
        this.setChecked(this.anyInput, false);
      } else if (this.valueInputs.every(vi => !vi.checked)) {
        this.setChecked(this.anyInput, true);
      }
    }
  }
}

export default function initCheckboxSelectorAny() {
  const selectors = document.querySelectorAll(".checkbox-selector-any");
  for (let i = 0; i < selectors.length; i++) {
    const selector = selectors[i];
    new CheckboxSelectorAny(selector);
  }
}
