export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
}
export const breakpointHeaderTablet = "xl";
export const breakpointHeaderMobile = "md";
export const breakpointSidebar = "xxl";

export function breakpointBelow(breakpoint) {
  // Повторяет логику bootstrap.
  const width = breakpoints[breakpoint] - 0.02;
  return window.matchMedia(`(max-width: ${width}px)`).matches;
}

export function breakpointAbove(breakpoint) {
  const width = breakpoints[breakpoint];
  return window.matchMedia(`(min-width: ${width}px)`).matches;
}
