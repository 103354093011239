export function isAttachmentCardCollapsed(element) {
  let button = element.querySelector(".attachment-card__button");
  return (
    button && !button.classList.contains("attachment-card__button--active")
  );
}

export function collapseAttachmentCard(element) {
  let button = element.querySelector(".attachment-card__button");
  if (button) {
    button.classList.remove("attachment-card__button--active");
  }

  let content = element.querySelector(".attachment-card__content");
  if (content) {
    $(content).slideUp("fast");
  }
}

export function uncollapseAttachmentCard(element) {
  let button = element.querySelector(".attachment-card__button");
  if (button) {
    button.classList.add("attachment-card__button--active");
  }

  let content = element.querySelector(".attachment-card__content");
  if (content) {
    $(content).slideDown("fast");
  }
}

export function toggleAttachmentCard(element) {
  if (isAttachmentCardCollapsed(element)) {
    uncollapseAttachmentCard(element);
  } else {
    collapseAttachmentCard(element);
  }
}

export default function initAttachmentCard() {
  $(document).on("click", ".attachment-card__button", function() {
    toggleAttachmentCard($(this).closest(".attachment-card")[0]);
  });
}
