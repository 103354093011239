function removeHints() {
  let hints = document.querySelectorAll(".scroll-hint");
  hints.forEach(hint => {
    hint.classList.add("scroll-hint--fade-away");
  });
  setTimeout(() => {
    hints.forEach(hint => hint.remove());
  }, 5000);
}

export default function initScrollHint() {
  let events = ["mousedown", "mousewheel", "touchstart"];
  let removed = false;
  for (const event of events) {
    document.addEventListener(
      event,
      () => {
        if (removed) return;
        removed = true;
        removeHints();
      },
      { once: true }
    );
  }
}
