window.Base64 = require("js-base64").Base64;

(function() {
  for (let key in globalWFData) {
    let value = globalWFData[key];
    let els = document.querySelectorAll(
      '.global-wf-data[data-hash="' + key + '"]'
    );
    for (let i = 0; i < els.length; i++) {
      els[i].outerHTML = Base64.decode(value);
    }
  }
})();
