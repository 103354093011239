import numberTemplate from "../../../templates/number_template.ejs";
import numberCardContainerTemplate from "../../../templates/number_card_container.ejs";
import lodashTemplate from "lodash/template";
import forEach from "lodash/forEach";
import {renderNoResultsMessage, renderProviderIcon} from "../templates";
import {store} from "../../react/store";
import initCatalogView from "./core";
import extraSearchParams from "./plugins/extra-search-params";
import loadMore from "./plugins/load-more";
import sortSelect from "./plugins/sort-select";
import filters from "./plugins/filters";
import dataLayer from "./plugins/data-layer";
import {pushNumbersApiDataLayer} from "../../utils/gtm";
import transformParams from "./plugins/transform-params";
import {cleanMaskedInteger} from "../forms/inputs/masks";
import autoLoadMore from "./plugins/auto-load-more";

function renderNumber(number) {
  const render = lodashTemplate(numberTemplate, {
    imports: {
      forEach: forEach,
      renderProviderIcon: renderProviderIcon
    }
  });
  const cartNumbers = store.getState().cart.cartNumbers;
  return render({
    isStarNumber: false,
    isSuperPair: false,
    itemObj: number,
    subdomain: SUBDOMAIN_URL,
    cartNumbers: cartNumbers,
  });
}

function renderNumberPage(numbers) {
  if (numbers.length === 0) {
    return renderNoResultsMessage();
  }

  const render = lodashTemplate(numberCardContainerTemplate, {
    imports: {
      forEach: forEach,
    }
  });

  const numberHtmls = numbers.map(num => renderNumber(num));
  return render({numbers: numberHtmls});
}

export const transformMaskedParams = transformParams({
  min_price: v => cleanMaskedInteger(v),
  max_price: v => cleanMaskedInteger(v),
  min_rent_price: v => cleanMaskedInteger(v),
  max_rent_price: v => cleanMaskedInteger(v),
});

export function initCatalogViewNumbersCatalog() {
  const elements = document.querySelectorAll(".js-catalog-view--numbers-catalog");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    initCatalogView(element, {
      renderPage: renderNumberPage,
      plugins: [
        extraSearchParams,
        loadMore,
        sortSelect,
        filters,
        transformMaskedParams,
        dataLayer({pushToDataLayer: options => pushNumbersApiDataLayer(options)}),
        autoLoadMore({xl: 2}),
      ],
    });
  }
}

export function initCatalogViewNumbersListing() {
  const elements = document.querySelectorAll(".js-catalog-view--numbers-listing");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    initCatalogView(element, {
      renderPage: renderNumberPage,
      plugins: [
        extraSearchParams,
        loadMore,
        sortSelect,
        filters,
        transformMaskedParams,
        dataLayer({pushToDataLayer: options => pushNumbersApiDataLayer(options)}),
      ],
    });
  }
}

export function initCatalogViewNumbersErrorPage() {
  const elements = document.querySelectorAll(".js-catalog-view--numbers-error-page");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    initCatalogView(element, {
      renderPage: renderNumberPage,
      plugins: [
        extraSearchParams,
        loadMore,
      ],
    });
  }
}
