import debounce from "lodash/debounce";
import {renderNoResultsMessage} from "../templates";

/// Только базовый функционал catalog view: загрузка и рендеринг.
export default function initCatalogView(element, options) {
  let view = {};
  view.element = element;
  view.contentRoot = element.querySelector(".js-catalog-view__content");
  view.url = element.getAttribute("data-url");
  view.renderPage = options.renderPage;

  view.renderMessage = message => {
    return renderNoResultsMessage(message);
  };

  view.getSearchParams = () => {
    return new URLSearchParams();
  };

  view.isLoading = false;
  view.setLoading = loading => {
    view.isLoading = loading;
  };

  view.request = async (extraParams) => {
    view.setLoading(true);

    try {
      const params = view.getSearchParams();
      copyParams(extraParams, params);

      const url = `${view.url}?${params.toString()}`;
      const resp = await fetch(url);
      if (resp.status !== 200) {
        throw new CatalogHttpError(resp.status);
      }

      return await resp.json();
    } finally {
      view.setLoading(false);
    }
  };

  view.reload = async (extraParams) => {
    view.debounceReload.cancel();

    try {
      const resp = await view.request(extraParams);
      view.contentRoot.innerHTML = view.renderPage(resp.results);
      return resp;
    } catch (e) {
      if (e.name === "CatalogHttpError" && e.status === 400) {
        view.contentRoot.innerHTML = view.renderMessage("Ничего не нашлось. Попробуйте исправить критерии поиска.");
      } else {
        view.contentRoot.innerHTML = view.renderMessage("Произошла ошибка. Попробуйте исправить критерии поиска.");
      }

      throw e;
    }
  };
  view.debounceReload = debounce(() => {
    view.reload();
  }, 1000);

  view.loadAppend = async (extraParams) => {
    const resp = await view.request(extraParams);
    const html = view.renderPage(resp.results);
    view.contentRoot.innerHTML += html;
    return resp;
  }

  for (const plugin of (options.plugins || [])) {
    view = plugin(view);
  }

  return view;
}

export function override(superFunc, overrideFunc) {
  return (...args) => {
    return overrideFunc(superFunc, ...args);
  };
}

export function copyParams(src, dst) {
  if (src) {
    src.forEach((value, key) => {
      dst.append(key, value);
    });
  }
}

export class CatalogHttpError extends Error {
  constructor(status) {
    super(`HTTP ${status}`);
    this.status = status;
    this.name = "CatalogHttpError";
  }
}
