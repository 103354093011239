import Cookies from "js-cookie";

let containerInputs = null;

function initContainerInputs() {
  if ("Map" in window) {
    containerInputs = new Map();
  }
}

function setContainerInput(container, input) {
  if (containerInputs) {
    containerInputs.set(container, input);
  }
}

function updateAllContainerPositions() {
  if (containerInputs) {
    containerInputs.forEach((input, container) => {
      updateContainerPosition(container, input);
    });
  }
}

function updateContainerPosition(container, input, options) {
  const defaultOptions = { force: false };
  options = Object.assign({}, defaultOptions, options);

  if (container.style.display === "none" && !options.force) return;

  let inputRect = input.getBoundingClientRect();
  container.style.left = `${window.scrollX + inputRect.left}px`;
  container.style.top = `${window.scrollY +
    inputRect.top +
    inputRect.height}px`;
  container.style.width = `${inputRect.width}px`;
}

export default function initEmailSuggestions() {
  if (window.suggestEmailEnabled) {
    initContainerInputs();

    $('input[type="email"]').suggestions({
      url: window.suggestEmailUrl,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken")
      },
      type: "EMAIL",
      floating: true,
      beforeRender: function($container) {
        // suggestions-jquery не размещает выпадающий список под инпутом при floating: true,
        // делаем это сами.
        let input = this;
        let container = $container[0];
        updateContainerPosition(container, input, { force: true });
        setContainerInput(container, input);
      }
    });

    // Поворот экрана
    window.addEventListener("resize", () => {
      updateAllContainerPositions();

      // После обновления модального окна на стороне fancybox
      setTimeout(() => {
        updateAllContainerPositions();
      }, 500);
    });
    window.addEventListener(
      "scroll",
      () => {
        updateAllContainerPositions();
      },
      true
    );
  }
}
