function initCatalogFilterGroupCounter(element) {
  const rootSelector = element.getAttribute("data-catalog-filter-group-root");
  const root = document.querySelector(rootSelector);
  const counter = element.querySelector(".js-catalog-filter-group-counter__counter");

  const update = () => {
    const count = countFilledGroups(root);
    counter.innerText = count.toString();
    if (count === 0) {
      counter.style.display = "none";
    } else {
      counter.style.display = "";
    }
  };

  root.addEventListener("input", update);
  root.addEventListener("change", update);
  update();
}

function countFilledGroups(root) {
  return root.querySelectorAll(".catalog-filter-group--filled").length;
}

export default function initCatalogFilterGroupCounterModule() {
  const elements = document.querySelectorAll(".js-catalog-filter-group-counter");
  for (const element of elements) {
    initCatalogFilterGroupCounter(element);
  }
}
