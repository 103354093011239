export function initPhoneHighlight() {
  let phone = $(".phone"),
    phoneHandset = $(".phone_handset"),
    phoneText;

  phone.each(function() {
    phoneText = $(this).text();
    $(this).text("");
    for (let i = 0; i < phoneText.length; i++) {
      if (phoneText[i] !== " ")
        $(this).append("<span>" + phoneText[i] + "</span>");
    }
  });
  phoneHandset.each(function() {
    $(this).text("");
    $(this).append("<span>" + "p" + "</span>");
  });
}
