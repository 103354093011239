export function initRegexpMethod() {
  $.validator.addMethod(
    "regexp",
    function(value, el, exp) {
      const rules = this.settings.rules;
      const mask = "+7 ( ___ ) ___ - __ - __";
      if (rules.hasOwnProperty(el.name)) {
        if ((value && value != mask) || rules[el.name].required) {
          return exp.test(value);
        }
      }
      return true;
    },
    "Введите корректный номер телефона."
  );
}

export const PHONE_REGEXP = /\+7 \( \d{3} \) \d{3} - \d{2} - \d{2}$/;
// Разрешает латиницу на месте скрытых цифр.
export const CONCEALED_PHONE_REGEXP = /\+7 \( [\da-zA-Z]{3} \) [\da-zA-Z]{3} - [\da-zA-Z]{2} - [\da-zA-Z]{2}$/;
export const STAR_PHONE_REGEXP = /\*?\d{1,4}$/;

export function initLuhnMethod() {
  $.validator.addMethod(
    "checkLuhn",
    function(value, el, exp) {
      const rules = this.settings.rules;
      if (rules.hasOwnProperty(el.name)) {
        if (value || rules[el.name].required) {
          if (/[^0-9-\s]+/.test(value)) return false;

          let nCheck = 0,
            nDigit = 0,
            bEven = false;
          value = value.replace(/\D/g, "");

          for (let n = value.length - 1; n >= 0; n--) {
            let cDigit = value.charAt(n),
              nDigit = parseInt(cDigit, 10);

            if (bEven) {
              if ((nDigit *= 2) > 9) nDigit -= 9;
            }

            nCheck += nDigit;
            bEven = !bEven;
          }

          return nCheck % 10 == 0;
        }
      }
      return true;
    },
    "Введите корректное значение дисконтной карты."
  );
}

export const MASK_REGEXP = /^[\-xyzabcdef0-9\*]{1,11}$/i;

export function initMaskMethod() {
  $.validator.addMethod(
    "mask",
    function(value, el, exp) {
      const rules = this.settings.rules;
      if (rules.hasOwnProperty(el.name)) {
        if (value || rules[el.name].required) {
          return exp.test(value);
        }
      }
      return true;
    },
    "Введите корректную маску номера."
  );
}

export const NAME_REGEXP = /.{2,}/;

export function initNameMethod() {
  $.validator.addMethod(
    "name_regexp",
    function(value, el, exp) {
      const rules = this.settings.rules;
      if (rules.hasOwnProperty(el.name)) {
        if (value || rules[el.name].required) {
          return exp.test(value);
        }
      }
      return true;
    },
    "В имени должно быть не менее двух символов."
  );
}

export function initIgnoreValidator() {
  $.validator.setDefaults({
    ignore: function(index, htmlElement) {
      let element = $(htmlElement);

      if (element) {
        if (element.hasClass("always-validate") && element.val()) {
          return false;
        }
        // Дефолт
        return element.is(":hidden");
      }
      return true;
    }
  });
}
