export function initCountdownTimers() {
  let date = new Date();
  date.setDate(date.getDate() + 1);

  // сформировать строковое представление следующего дня в формате yyyy/mm/dd с лидирующий нулями
  // на чистом JavaScript
  let tomorrowString =
    date.getFullYear() +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + date.getDate()).slice(-2);

  $(".timer__content").countdown(tomorrowString, function(event) {
    $(this).text(event.strftime("%H:%M:%S"));
  });
}
