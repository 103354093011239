import numberTemplate from "../../../templates/number_template.ejs";
import numberCardContainerTemplate from "../../../templates/number_card_container.ejs";
import lodashTemplate from "lodash/template";
import forEach from "lodash/forEach";
import {renderNoResultsMessage, renderProviderIcon} from "../templates";
import {store} from "../../react/store";
import initCatalogView from "./core";
import extraSearchParams from "./plugins/extra-search-params";
import loadMore from "./plugins/load-more";
import sortSelect from "./plugins/sort-select";
import filters from "./plugins/filters";
import {transformMaskedParams} from "./numbers";
import autoLoadMore from "./plugins/auto-load-more";

function renderNumberSet(numberset) {
  const render = lodashTemplate(numberTemplate, {
    imports: {
      forEach: forEach,
      renderProviderIcon: renderProviderIcon
    }
  });
  const cartNumbers = store.getState().cart.cartNumbers;
  const inCart = numberset.numbers.every(number => cartNumbers.indexOf(number.id) !== -1);
  return render({
    isStarNumber: false,
    isSuperPair: true,
    itemObj: numberset,
    subdomain: SUBDOMAIN_URL,
    in_cart: inCart,
  });
}

function renderNumberSetPage(numbers) {
  if (numbers.length === 0) {
    return renderNoResultsMessage();
  }

  const render = lodashTemplate(numberCardContainerTemplate, {
    imports: {
      forEach: forEach,
    }
  });

  const numberHtmls = numbers.map(num => renderNumberSet(num));
  return render({numbers: numberHtmls});
}

export default function initCatalogViewNumberSetCatalog() {
  const elements = document.querySelectorAll(".js-catalog-view--numberset-catalog");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    initCatalogView(element, {
      renderPage: renderNumberSetPage,
      plugins: [
        extraSearchParams,
        loadMore,
        sortSelect,
        filters,
        transformMaskedParams,
        autoLoadMore({xl: 2}),
      ],
    });
  }
}
