import { getStocks } from "../../utils/api";
import { renderPlainStockItem, renderNoResultsMessage } from "../templates";

export function initStocks() {
  let moreStocksButton = $("#more_stocks"),
    stockList = $("#stock_list"),
    providerFilters = $("#stock_provider_filters"),
    page = 2;

  moreStocksButton.click(function(e) {
    let params = providerFilters.serialize();

    moreStocksButton.text("Загрузка");

    getStocks(params, page)
      .then(data => {
        moreStocksButton.text("Показать еще акции");
        if (data.next) {
          page += 1;
        } else {
          moreStocksButton.hide();
        }

        let html = data.results
          .map(function(stock) {
            return renderPlainStockItem(stock);
          })
          .join("");
        stockList.append(html);
      })
      .fail(() => {
        moreStocksButton.text("Показать еще акции");
      });

    e.preventDefault();
  });

  providerFilters.on("change", function() {
    let params = providerFilters.serialize(),
      page = 1;

    getStocks(params, page).then(data => {
      if (data.next) {
        page += 1;
        moreStocksButton.show();
      } else {
        moreStocksButton.hide();
      }

      let html;
      if (data.results.length > 0) {
        html = data.results
          .map(function(stock) {
            return renderPlainStockItem(stock);
          })
          .join("");
      } else {
        html = renderNoResultsMessage("Ничего не найдено");
      }
      stockList.html(html);
    });
  });
}
