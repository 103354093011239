function initCheckboxSelectorExclusive(element) {
  element.addEventListener("change", event => {
    if (event.target.tagName !== "INPUT" || event.target.type !== "checkbox") {
      return;
    }

    if (!event.target.checked) {
      return;
    }

    const allCheckboxes = element.querySelectorAll("input[type=checkbox]");
    for (const checkbox of allCheckboxes) {
      if (checkbox === event.target) {
        continue;
      }

      if (checkbox.checked) {
        checkbox.checked = false;
        checkbox.dispatchEvent(new Event("change", {bubbles: true}));
      }
    }
  });
}

export default function initCheckboxSelectorExclusiveModule() {
  const elements = document.querySelectorAll(".checkbox-selector-exclusive");
  for (const element of elements) {
    initCheckboxSelectorExclusive(element);
  }
}
