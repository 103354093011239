import Cookies from "js-cookie";

export function getFromApi(url, data) {
  return $.ajax({
    url: url,
    contentType: "application/json; charset=UTF-8",
    data: data,
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken")
    },
    dataType: "json",
    method: "GET"
  });
}

export function postToApi(url, data) {
  return $.ajax({
    url: url,
    contentType: "application/json; charset=UTF-8",
    data: JSON.stringify(data),
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken")
    },
    dataType: "json",
    method: "POST"
  });
}

export function putToApi(url, data) {
  return $.ajax({
    url: url,
    contentType: "application/json; charset=UTF-8",
    data: JSON.stringify(data),
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken")
    },
    dataType: "json",
    method: "PUT"
  });
}

export function deleteFromApi(url, data) {
  return $.ajax({
    url: url,
    contentType: "application/json; charset=UTF-8",
    data: JSON.stringify(data),
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken")
    },
    dataType: "json",
    method: "DELETE"
  });
}

/**
 * Очередь AJAX-запросов JQuery. Позволяет выполнять запросы последовательно.
 * Пример:
 *
 *   let queue = new AjaxQueue();
 *   queue.push(() => $.ajax({...}));
 *   queue.push(() => $.ajax({...}));
 *
 * push возвращает объект Deferred, на котором можно вызывать методы done/fail/always/then
 * так же, как если бы $.ajax был вызван напрямую:
 *
 *   queue
 *     .push(() => $.ajax({...}))
 *     .done(data => {
 *       console.log(data);
 *     })
 */
export class AjaxQueue {
  constructor() {
    this.lastDeferred = null;
  }

  push(callback) {
    // Инициализируем lastDeferred лениво, потому что на момент вызова конструктора
    // переменная $ может быть еще не объявлена.
    if (!this.lastDeferred) {
      this.lastDeferred = $.Deferred().resolve();
    }

    let newDeferred = $.Deferred();
    let run = () => {
      let xhr = callback();
      xhr.then(newDeferred.resolve, newDeferred.reject);
      return xhr;
    };
    this.lastDeferred = this.lastDeferred.then(run, run);
    return newDeferred;
  }
}

const TARIFFS_LIST = "/api/tariffs/tariffs-catalog/";
const SUITABLE_NUMBERS_LIST = "/api/tariffs/suitable-numbers/";
const SUITABLE_TARIFFS_LIST = "/api/numbers/suitable-tariffs/";
const DEVICE_SUITABLE_TARIFFS_LIST = "/api/device-suitable-tariffs/";
const NEWS_LIST = "/api/news/";
const SUBDOMAINS_LIST = "/api/subdomains/";
const FEEDBACKS_LIST = "/api/load-more-reviews/";
const ARTICLES_LIST = "/api/authors/articles/";
const STOCKS_LIST = "/api/stocks/";
const SEARCH_HEAP = "/api/search/heap/";

export function getTariffs(
  params,
  page = 1,
  pageSize = 20,
  endpoint = TARIFFS_LIST
) {
  page = ["page", page].join("=");
  pageSize = ["page_size", pageSize].join("=");

  params = [params, page, pageSize].filter(o => o).join("&");
  let url = [endpoint, params].join("?");

  return $.ajax({
    url: url
  });
}

export function searchHeap(query) {
  return $.ajax({
    url: SEARCH_HEAP,
    data: $.param({
      query: query
    })
  });
}

export function getSuitableNumbersList(
  tariff,
  page = 1,
  pageSize = 20,
  parameters = "",
  endpoint = SUITABLE_NUMBERS_LIST
) {
  const tariffEndpoint = [endpoint, tariff, "/"].join("");
  return getTariffs(parameters, page, pageSize, tariffEndpoint);
}

export function getSuitableTariffsList(
  tariff,
  page = 1,
  pageSize = 20,
  parameters = "",
  endpoint = SUITABLE_TARIFFS_LIST
) {
  const tariffEndpoint = [endpoint, tariff, "/"].join("");
  return getTariffs(parameters, page, pageSize, tariffEndpoint);
}

export function getDeviceSuitableTariffsList(
  tariff,
  page = 1,
  pageSize = 20,
  parameters = "",
  endpoint = DEVICE_SUITABLE_TARIFFS_LIST
) {
  const tariffEndpoint = [endpoint, tariff, "/"].join("");
  return getTariffs(parameters, page, pageSize, tariffEndpoint);
}

export function getNews(params = "", page = 1) {
  page = ["page", page].join("=");
  params = [params, page].filter(o => o).join("&");
  params = [params].join("?");
  return $.ajax({
    url: NEWS_LIST,
    data: params
  });
}

export function getSubdomains() {
  return $.ajax({
    url: SUBDOMAINS_LIST
  });
}

export function getFeedbacks(page = 1) {
  let params;
  page = ["page", page].join("=");
  params = [page].join("?");
  return $.ajax({
    url: FEEDBACKS_LIST,
    data: params
  });
}

export function getArticles(params = "", page = 1) {
  page = ["page", page].join("=");
  params = [params, page].filter(o => o).join("&");
  params = [params].join("?");
  return $.ajax({
    url: ARTICLES_LIST,
    data: params
  });
}

export function getStocks(params = "", page = 1) {
  page = ["page", page].join("=");
  params = [params, page].filter(o => o).join("&");
  params = [params].join("?");
  return $.ajax({
    url: STOCKS_LIST,
    data: params
  });
}
