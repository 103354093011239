export default function initTabbed() {
  $(".tabbed__tab").on("click", function() {
    let tab = $(this);
    let targetSelector = tab.attr("data-target");
    let block = tab.closest(".tabbed");
    let target = block.find(targetSelector);

    block.find(".tabbed__content").hide();
    target.fadeIn("fast");

    block.find(".tabbed__tab").removeClass("tabbed__tab--active");
    tab.addClass("tabbed__tab--active");

    // Отключаем все включенные radio-кнопки снаружи текущей вкладки.
    block
      .find("input[type=radio]:checked")
      .not(tab.find("input[type=radio]"))
      .prop("checked", false)
      .each(function() {
        this.dispatchEvent(new Event("change", { bubbles: true }));
      });
    // Включаем все отключенные radio-кнопки внутри текущей вкладки.
    tab
      .find("input[type=radio]:not(:checked)")
      .prop("checked", true)
      .each(function() {
        this.dispatchEvent(new Event("change", { bubbles: true }));
      });
  });
}
