export function IsComponentMountedMixin(SuperComponent) {
  return class extends SuperComponent {
    constructor(props) {
      super(props);
      this.isComponentMounted = false;
    }

    componentDidMount() {
      this.isComponentMounted = true;
      if (super.componentDidMount) super.componentDidMount();
    }

    componentWillUnmount() {
      if (super.componentWillUnmount) super.componentWillUnmount();
      this.isComponentMounted = false;
    }
  };
}
