import {searchHeap} from "../../utils/api";
import React from "react";
import ReactDOM from "react-dom";
import SearchSuggestions from "../../react/components/search/SearchSuggestions";
import {showPreloaderSuggestionsCreator, updateSearchSuggestionsCreator} from "../../react/modules/search";
import {store} from "../../react/store";
import {breakpointBelow, breakpointHeaderTablet} from "../../utils/breakpoints";

const SUGGESTIONS_DROPDOWN = "search-suggestions-dropdown";
const SUGGESTIONS_MOBILE = "search-suggestions-mobile";

function renderSuggestionsPopup(rootId) {
  const target = document.getElementById(rootId);
  if (target) {
    ReactDOM.render(
      React.createElement(SearchSuggestions, { store: store }),
      target
    );
  }
}

function showPopup(rootId) {
  $(`#${rootId} .search-popup`).show();
}

function hidePopup(rootId) {
  $(`#${rootId} .search-popup`).fadeOut("fast");
}

function getPopup(rootId) {
  return document.querySelector(`#${rootId} .search-popup`);
}

class BaseForm {
  constructor(rootId) {
    this.form = document.getElementById(rootId);
    this.queryInput = this.form.querySelector(".header-search-bar__input");
    this.button = this.form.querySelector(".header-search-bar__button");
    this.timeouts = [];

    this.init();
  }

  init() {
    this.queryInput.addEventListener("input", () => this.reloadDelayed());
    this.queryInput.addEventListener("propertychange", () => this.reloadDelayed());
  }

  reload() {
    const query = this.queryInput.value;
    if (!query || query.length < 3) {
      return;
    }

    this.showPopup();

    store.dispatch(showPreloaderSuggestionsCreator());
    searchHeap(query).then(data => {
      store.dispatch(updateSearchSuggestionsCreator(data, query));
    });
  }

  reloadDelayed() {
    this.timeouts.forEach(id => {
      clearTimeout(id);
    });
    let lastId = setTimeout(this.reload.bind(this), 300);
    this.timeouts.push(lastId);
  }

  showPopup() {}
}

class DropdownForm extends BaseForm {
  init() {
    super.init();

    $(document).mouseup(function(e) {
      const insideSearchBar = !!e.target.closest(".header-search-bar");
      const insideSearchButton = !!e.target.closest(".header-search-bar__button");

      if (insideSearchButton || !insideSearchBar) {
        hidePopup(SUGGESTIONS_DROPDOWN);
      }
    });
  }

  showPopup() {
    showPopup(SUGGESTIONS_DROPDOWN);
  }
}

class MobileForm extends BaseForm {
  init() {
    super.init();

    this.button.addEventListener("click", () => {
      hidePopup(SUGGESTIONS_MOBILE);
      this.queryInput.value = "";
      document.body.classList.remove("header-menu-lock-scrolling");
      this.form.classList.remove("header-search-bar--active");
    });
  }

  showPopup() {
    showPopup(SUGGESTIONS_MOBILE);
    document.body.classList.add("header-menu-lock-scrolling");
    this.form.classList.add("header-search-bar--active");

    const headerNav = document.querySelector(".header__nav-row");
    if (headerNav) {
      const bottomOffset = headerNav.getBoundingClientRect().bottom;
      getPopup(SUGGESTIONS_MOBILE).style.height = `calc(100vh - ${bottomOffset}px)`;
    }
  }
}

function initExpandableSearchBar() {
  const header = document.querySelector(".header");
  if (!header) {
    return;
  }

  const searchBar = header.querySelector(".header-search-bar");
  if (!searchBar) {
    return;
  }

  const button = searchBar.querySelector(".header-search-bar__button");
  if (!button) {
    return;
  }

  button.addEventListener("click", event => {
    header.classList.toggle("header--search-bar-expanded");

    const isExpandable = breakpointBelow(breakpointHeaderTablet);
    if (!isExpandable) {
      searchBar.submit();
    }
  });
}

export default function initHeaderSearchBar() {
  renderSuggestionsPopup(SUGGESTIONS_DROPDOWN);
  renderSuggestionsPopup(SUGGESTIONS_MOBILE);

  new DropdownForm("search-form-header-dropdown");
  new MobileForm("search-form-header-mobile");

  initExpandableSearchBar();
}
