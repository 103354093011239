export default function initToggler() {
  let elements = document.querySelectorAll(".toggler");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    initSingleToggler(element);
  }
}

function initSingleToggler(baseElement) {
  baseElement.addEventListener("click", event => {
    event.preventDefault();

    toggleCollapseTarget(baseElement);
    toggleDisableTarget(baseElement);
  });
}

function toggleCollapseTarget(baseElement) {
  let targetSelector = baseElement.getAttribute("data-collapse");
  if (!targetSelector) return;

  let targets = document.querySelectorAll(targetSelector);
  for (let i = 0; i < targets.length; i++) {
    const target = targets[i];
    $(target).slideToggle("fast");
  }
}

function toggleDisableTarget(baseElement) {
  let targetSelector = baseElement.getAttribute("data-disable");
  if (!targetSelector) return;

  let targets = document.querySelectorAll(targetSelector);
  for (let i = 0; i < targets.length; i++) {
    const target = targets[i];
    target.disabled = !target.disabled;
  }
}
