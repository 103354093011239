export class CompactNumbersFilter {
  constructor(baseElement) {
    this.baseElement = baseElement;

    this.filterButtons.forEach(button => {
      this.initFilterButton(button);
    });
  }

  get filterButtons() {
    return Array.from(
      this.baseElement.querySelectorAll("[data-filter-button]")
    );
  }

  initFilterButton(button) {
    let action = button.getAttribute("data-filter-button");
    if (action === "simple-search") {
      button.addEventListener("click", () => {
        this.switchToSimpleSearch();
      });
    } else if (action === "advanced-search") {
      button.addEventListener("click", () => {
        this.switchAdvancedSearch();
      });
    } else {
      console.warn(
        `CompactNumbersFilter: Invalid filter button action`,
        button
      );
    }
  }

  switchToSimpleSearch() {
    this.baseElement.classList.remove("compact-numbers-filter--advanced");
    this.baseElement.classList.add("compact-numbers-filter--simple");
  }

  switchAdvancedSearch() {
    this.baseElement.classList.add("compact-numbers-filter--advanced");
    this.baseElement.classList.remove("compact-numbers-filter--simple");
  }
}

export default function initCompactNumbersFilter() {
  document.querySelectorAll(".compact-numbers-filter").forEach(element => {
    new CompactNumbersFilter(element);
  });
}
