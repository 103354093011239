import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import CartItem from "./CartItem";
import OrderEmptyMessage from "./OrderEmptyMessage";
import CurrencySymbol from "../CurrencySymbol";
import classNames from "classnames";

export class Cart extends React.Component {
  renderContent() {
    const { items, totalPrice, itemsCount } = this.props;

    if (itemsCount > 0) {
      return (
        <div className="small-card-container">
          {items.map(item => (
            <div key={item.id}>
              <CartItem item={item} />
            </div>
          ))}
          <div className="p-20">
            <div className="cart-invoice-list">
              <div className="cart-invoice-list__icon cart-invoice-list__icon--fix-width" />
              <div className="cart-invoice-list__list">
                <div className="cart-invoice-list__item cart-invoice-list__item--total">
                  <div className="cart-invoice-list__key">Итого</div>
                  <div className="cart-invoice-list__value fw-700">
                    <span className="price-inline">
                      {totalPrice} <CurrencySymbol />
                    </span>
                  </div>
                </div>
                <a href="/order/" className="button">
                  Оформить заказ
                </a>
              </div>
              <div className="cart-invoice-list__icon w-24" />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-40">
          <OrderEmptyMessage />
        </div>
      );
    }
  }

  render() {
    const { itemsCount } = this.props;

    return (
      <div className={classNames("cart-button", {"cart-button--empty": !itemsCount})}>
        <a href="/order/" className="cart-button__button cart-button__button--right-rounded">
          <div className="cart-button__button-icon" />
          <div className="cart-button__button-text">Корзина:</div>
          <div className="cart-button__button-count">{itemsCount}</div>
        </a>
        <div className="cart-button__dropdown">{this.renderContent()}</div>
      </div>
    );
  }
}

Cart.propTypes = {
  items: PropTypes.array,
  totalPrice: PropTypes.string,
  itemsCount: PropTypes.number
};

export function mapStateToProps(state) {
  return {
    items: state.cart.items,
    totalPrice: state.cart.totalPrice,
    itemsCount: state.cart.itemsCount,
    suitableTariffs: state.suitableTariffs
  };
}

export default connect(mapStateToProps)(Cart);
