import throttle from "lodash/throttle";

function autoStickNavRow() {
  const header = document.querySelector(".header");
  if (!header) {
    return;
  }

  const detailsRow = document.querySelector(".header__details-row");
  const navRow = document.querySelector(".header__nav-row");
  if (!navRow) {
    return;
  }

  function update() {
    const headerRect = header.getBoundingClientRect();
    const detailsHeight = detailsRow.getBoundingClientRect().height;
    const navHeight = navRow.getBoundingClientRect().height;
    if (window.scrollY > detailsHeight) {
      header.classList.add("header--sticky");
      header.style.height = `${detailsHeight + navHeight}px`;
      navRow.style.left = `${headerRect.left}px`;
      navRow.style.right = `${headerRect.right - headerRect.width}px`;
    } else {
      header.classList.remove("header--sticky");
      header.style.height = null;
      navRow.style.left = null;
      navRow.style.right = null;
    }
  }
  const updateDebounced = throttle(update, 5);

  update();
  document.addEventListener("scroll", () => updateDebounced());
  window.addEventListener("resize", () => updateDebounced());
}

export function getStickyNavHeight() {
  const navRow = document.querySelector(".header__nav-row");
  if (!navRow) {
    return 0;
  }

  return navRow.getBoundingClientRect().height;
}

function unshiftMenuScroll() {
  // По-умолчанию горизонтальный контент скроллится с помощью Shift + колесико.
  // Для меню шапки включаем скроллинг без Shift.
  const menu = document.querySelector(".header__menu");
  menu.addEventListener("wheel", event => {
    const isTouchScreen = window.matchMedia("(pointer: coarse)").matches;
    if (isTouchScreen) {
      return;
    }

    if (!event.deltaX) {
      event.preventDefault();
      // deltaY, а не deltaX, потому что по-умолчанию это вертикальный скроллинг.
      menu.scrollLeft += event.deltaY / 5;
    }
  });
}

export default function initHeader() {
  autoStickNavRow();
  unshiftMenuScroll();
}
